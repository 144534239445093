import {
  devBaseUrl,
  liveBaseUrl,
  localBaseUrl,
  testBaseUrl,
} from "../BaseUrl";

export function getBaseUrl(host) {
  if (host === "localhost") {
    return localBaseUrl;
  } else if (host === "mentor.stage.rankplus.live") {
    return testBaseUrl;
  } else if (host === "mentor.rankplus.live") {
    return liveBaseUrl;
  } else if (host === "dev.mentor.rankplus.live") {
    return devBaseUrl;
  }
}
