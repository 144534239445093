import React, { useState } from "react";
import { Box, Divider, Flex, HStack, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { AiFillAppstore, AiOutlineCalendar } from "react-icons/ai";
import { ROUTES } from "../../Constants/Routes";

import { useAppContext, useIsAuthenticated } from "../../App/Context";
import { useSelector } from "react-redux";
import { concat } from "lodash";

const allMenuOptions = [
   
];

export const Sidebar = () => {
    return (
        <Box className="sidebar" boxShadow="1px 0px 14px 0px rgba(174, 182, 191, 0.2)" height="100%" bg="white">
            <SideBarContent />
        </Box>
    );
};

export const SideBarContent = ({ top }) => {
    const currentRoute = useLocation().pathname;
    let menuOptions = allMenuOptions;
    const user = useSelector((s) => s.user.user);
    const isAuthenticated = useIsAuthenticated();
    // if(user?.staff?.staffRole === 'SUPPORT_EXECUTIVE'){
    //   menuOptions = concat(menuOptions , {
    //     index: 0,
    //     route: "/desk",
    //     label: "Desk",
    //     icon: <AiFillAppstore />,
    //   })
    // }else{
    menuOptions = concat(
        menuOptions,
        {
            index: 5,
            route: "/mentorship",
            label: "Mentorship",
            icon: <AiFillAppstore />,
        },
        {
            index: 4,
            route: "/schedule",
            label: "Schedule",
            icon: <AiFillAppstore />,
        },
        {
            index: 3,
            route: "/threeD",
            label: "3D-AR-VR",
            icon: <AiFillAppstore />,
        },
        {
            index: 0,
            route: "/doubts",
            label: "Doubts",
            icon: <AiFillAppstore />,
        },
        {
            index: 1,
            route: "/callbacks",
            label: "Appointments",
            icon: <AiOutlineCalendar />,
        },
        {
            index: 2,
            route: "/chat",
            label: "Chat",
            icon: <AiOutlineCalendar />,
        }
    );
    // }
    const index = menuOptions.filter((opt) => {
        return opt.route === currentRoute;
    })[0]?.index;

    const [activeIndex, setActiveIndex] = useState(index || 4);

    const history = useHistory();

    const itemStyle = (index) => {
        if (index === activeIndex) {
            return {
                background: "primaryBlue.400",
                padding: "0.5rem 1rem",
                transition: "all 0.3s",
                color: "white",
            };
        } else {
            return {
                color: "#3C4043",
                // borderBottom :  "1px solid rgba(0,0,0,0.1)",
                padding: "0.5rem 1rem",
                transition: "all 0.3s",
                _hover: {
                    background: "#d2e2fd",
                },
                background: "white",
            };
        }
    };

    const handleItemClick = (item) => {
        history.push(item.route);
        setActiveIndex(item.index);
    };

    const { logout } = useAppContext();
    if (top) {
        return (
            <Flex>
                {isAuthenticated
                    ?
                    menuOptions?.map((opt, i) => {
                        return (
                            opt.label && (
                                <Box key={i}>
                                    <Text
                                        onClick={() => handleItemClick(opt)}
                                        cursor="pointer"
                                        color={opt.index !== activeIndex ? "white" : "accent.500"}
                                        px={6}
                                        py={3}
                                        _hover={{ color: `${opt.index == activeIndex ? "accent.500" : "accent.300"}` }}
                                        fontSize={"sm"}
                                    >
                                        {opt.label}
                                    </Text>
                                </Box>
                            )
                        );
                    })
                    : <></>
                }
            </Flex>
        );
    }
    return (
        <Box>
            {menuOptions?.map((opt, i) => {
                return !opt.label ? (
                    <Box key={i} h={"16px"}></Box>
                ) : (
                    <Box key={i} bg="white">
                        <Box
                            fontSize="16px"
                            cursor="pointer"
                            onClick={() => {
                                if (opt.logout) {
                                    return logout();
                                }
                                handleItemClick(opt);
                            }}
                            key={opt.index}
                            {...itemStyle(opt.index)}
                        >
                            <HStack spacing={4} p={1}>
                                <Box fontSize="lg" color={opt.index == activeIndex ? "white" : "primaryBlue.400"}>
                                    {opt.icon}
                                </Box>
                                <Box fontSize="sm">{opt.label}</Box>
                            </HStack>
                        </Box>
                        <Box px={4}>
                            <Divider />
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};
