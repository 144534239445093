import React, { useEffect, useState } from 'react'
import EditModal from './EditModal'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Flex, Heading, HStack, Text } from '@chakra-ui/react';
import { AiOutlineCalendar } from 'react-icons/ai';
import moment from 'moment';
import CustomAlertDialog from './CustomAlertDialog';
import { deletePlannerEventRequestAction, getUserScheduleAction, updatePlannerEventRescheduleAction } from '../../redux/reducers/planner';
import { STATUS } from '../../App/Constants';

const RequestModule = ({ show, toggleModal, selectedDate }) => {
    const dispatch = useDispatch();
    const [deleteEventRequest, setDeleteEventRequest] = useState(false);
    const [EventRequest, setEventRequest] = useState(false);
    const [selectedEventData, setSelectedEventData] = useState(false);
    const [requestSelectedStatus, setRequestSelectedStatus] = useState();

    const { user, plannerRequestList, deletePlannerEventRequestListStatus, updatePlannerEventRescheduleStatus, } = useSelector((s) => ({
        user: s.user.user,
        plannerRequestList: s.planner.plannerRequestList,
        deletePlannerEventRequestListStatus: s.planner.deletePlannerEventRequestListStatus,
        updatePlannerEventRescheduleStatus: s.planner.updatePlannerEventRescheduleStatus,
        updatePlannerEventRescheduleStatus: s.planner.updatePlannerEventRescheduleStatus,
    }));
    useEffect(() => {
        if (deletePlannerEventRequestListStatus === STATUS.SUCCESS) {
            dispatch(getPlannerScheduleAction({ staffIds: [user?.staff?._id], date: moment(selectedDate).format("YYYY-MM-DD") }));
           
            setDeleteEventRequest(false);
            toggleModal();
        } else if (updatePlannerEventRescheduleStatus === STATUS.SUCCESS) {
            setEventRequest(false);
            toggleModal();
        }
    }, [deletePlannerEventRequestListStatus, updatePlannerEventRescheduleStatus])

    const handleRequestDelete = (request) => {
        setSelectedEventData(request);
        setDeleteEventRequest(true);
    }

    const handleSwapRequest = (request, status) => {
        setRequestSelectedStatus(status);
        setSelectedEventData(request);
        setEventRequest(true);
    }

    const _handleRequest = () => {
       
        dispatch(updatePlannerEventRescheduleAction({
            _id: selectedEventData?._id,
            status: requestSelectedStatus === 'approve' ? true : false
        }))
    };

    const _deleteRequest = () => {
        dispatch(deletePlannerEventRequestAction({ _id: selectedEventData?._id }));
    };


    return (
        <EditModal
            isOpen={show}
            onClose={toggleModal}
            title={"Requests"}
            size={"xl"}
           
            footer={false}
        >
            <Flex gap={20} style={{ gap: "20px" }}>
                <Box>
                    {plannerRequestList && plannerRequestList?.requestRecive?.length > 0
                        ? plannerRequestList?.requestRecive?.map((request, i) => {
                            const startDate = moment(request?.eventId?.startDate).format("hh:mm a");
                            const endDate = moment(request?.eventId?.endDate).format("hh:mm a");
                            return (
                                <Box key={i + 1}>
                                    <Flex justify="space-between">
                                        <Box flex={1}>
                                            <HStack color="gray.600" mt={4} spacing={2} w="100%">
                                                <Box>
                                                    {moment(request?.eventId?.startDate).format("MMM DD, YYYY")}
                                                </Box>
                                                <Flex align="center">
                                                    <Box mr={1} color="blue.600">
                                                        <AiOutlineCalendar />
                                                    </Box>
                                                    {startDate} - {endDate}
                                                </Flex>
                                                {request?.eventId?.classStatus && request?.eventId?.classStatus !== "" && request?.eventId?.classStatus !== undefined &&
                                                    <Box background={"#3182ce"} color={"white"} fontSize={"13px"} paddingX={"5px"} borderRadius={"5px"}>
                                                        <Text>{request?.eventId?.classStatus}</Text>
                                                    </Box>
                                                }
                                            </HStack>
                                            <HStack justify={"space-between"}>
                                                <Heading
                                                    fontSize="sm"
                                                    mt={2}
                                                >{request?.eventId?.topic}</Heading>
                                                
                                            </HStack>
                                        </Box>
                                    </Flex>
                                    <Flex justify="end">
                                        <HStack spacing='15px'>
                                            <Button colorScheme="blue" size="sm"
                                                isDisabled={request?.rescheduleStatus === "Approved" || request?.approvedbyMentor === true}
                                                onClick={() => handleSwapRequest(request, "approve")}>
                                                Accept
                                            </Button>
                                            <Button colorScheme="red" size="sm"
                                                isDisabled={request?.rescheduleStatus === "Approved" || request?.approvedbyMentor === true}
                                                onClick={() => handleSwapRequest(request, 'delete')}>
                                                Revoke
                                            </Button>
                                        </HStack>
                                    </Flex>
                                    <Divider mt={4} />
                                </Box>
                            )
                        })
                        : <Text> No request received </Text>
                    }
                </Box>
                <Box>
                    {plannerRequestList && plannerRequestList?.requestSend?.length > 0
                        ? plannerRequestList?.requestSend?.map((request, i) => {
                            const startDate = moment(request?.eventId?.startDate).format("hh:mm a");
                            const endDate = moment(request?.eventId?.endDate).format("hh:mm a");
                            return (
                                <Box key={i + 1}>
                                    <Flex justify="space-between">
                                        <Box flex={1}>
                                            <HStack color="gray.600" mt={4} spacing={2} w="100%">
                                                <Box>
                                                    {moment(request?.eventId?.startDate).format("MMM DD, YYYY")}
                                                </Box>
                                                <Flex align="center">
                                                    <Box mr={1} color="blue.600">
                                                        <AiOutlineCalendar />
                                                    </Box>
                                                    {startDate} - {endDate}
                                                </Flex>
                                                {request?.eventId?.classStatus && request?.eventId?.classStatus !== "" && request?.eventId?.classStatus !== undefined &&
                                                    <Box background={"#3182ce"} color={"white"} fontSize={"13px"} paddingX={"5px"} borderRadius={"5px"}>
                                                        <Text>{request?.eventId?.classStatus}</Text>
                                                    </Box>
                                                }
                                            </HStack>
                                            <HStack justify={"space-between"}>
                                                <Heading
                                                    fontSize="sm"
                                                    mt={2}
                                                >{request?.eventId?.topic}</Heading>
                                               
                                            </HStack>
                                        </Box>
                                    </Flex>
                                    <Flex justify="end">
                                        <HStack spacing='15px'>
                                            <Button colorScheme="red" size="sm"
                                                isDisabled={request?.rescheduleStatus === "Approved" || request?.approvedbyMentor === true}
                                                onClick={() => handleRequestDelete(request)}>
                                                Revoke
                                            </Button>
                                        </HStack>
                                    </Flex>
                                    <Divider mt={4} />
                                </Box>
                            )
                        })
                        : <Text>No request send</Text>
                    }
                </Box>
            </Flex>
            <CustomAlertDialog
                title={"Delete Request"}
                isOpen={deleteEventRequest}
                onClose={() => setDeleteEventRequest(false)}
                onSubmit={_deleteRequest}
            >
                Are you sure? You want to delete request.
            </CustomAlertDialog>
            <CustomAlertDialog
                title={"Accept Request"}
                isOpen={EventRequest}
                onClose={() => setEventRequest(false)}
                onSubmit={_handleRequest}
            >
                Are you sure? You want to accept request.
            </CustomAlertDialog>
        </EditModal >
    )
}

export default RequestModule