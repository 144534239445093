import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppContext, { useAppContext } from './Context';
import {
    setAuthorizationHeader,
    removeAuthorizationHeader,
} from '../services/api';
import { useCallback } from 'react';
import {
    logoutAction,
    requestUserProfileAction,
} from '../redux/reducers/user';
import { useCheckStatus } from '../utils/useCheckStatus';
import { Box, Button, Center, Spinner, Text, useToast } from '@chakra-ui/react';
import { getDefaultDataAction } from '../redux/reducers/lmsConfig';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { isSSR } from '../utils/ssrHelper';
import { apis } from '../services/api/apis';
import { Helmet } from 'react-helmet';
global._ = _;
//to initialize firebase
export const token = React.createRef();

export const getLoginData = () => {
    if (isSSR) {
        return 0;
    } else {
        return localStorage.getItem('@login/coco/public');
    }
};

export const AppProvider = ({ children, navigation }) => {
    const serverToken = useSelector(s => s.user.token);

    // if (isSSR) {
    //   // return
    // } else {
    //   const urlSearchParams = new URLSearchParams(window.location.search);
    //   const params = Object.fromEntries(urlSearchParams.entries());
    // }

    const [appstate, setAppState] = useState(false);
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies();

    const { status, user } = useSelector(s => ({
        status: s.user.status,
        user: s.user.user,
        // student: s.user.student,
    }));

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (!isSSR) {
            window.scrollTo(0, 0);
        }
    }, [location]);

    const [isAuthenticated, setAuthenticated] = useState(() => {
        if (
            serverToken ||
            (user &&
                cookies &&
                cookies['coco-user'] &&
                cookies['coco-user'] !== null &&
                cookies['coco-user'] !== 'null')
        ) {
            setAuthorizationHeader(serverToken || cookies['coco-user']);
            return true;
        } else {
            return false;
        }
    });

    const updateDevice = useCallback(() => {
        // dispatch(
        //   updateUserDevice({
        //     fcmToken: window.fcmToken,
        //     lastAccessedAt: new Date(),
        //   }),
        // );
    }, []);

    const loginSuccess = useCallback(
        data => {
            setAuthorizationHeader(data.token);
            token.current = data.token;
            dispatch(requestUserProfileAction());

            // !serverToken && dispatch(requestUserCartAction());
            if (!isSSR && !serverToken) {
                updateDevice();
            }

            // if(getCart()){
            //   dispatch(addPackageToUserCartAction({packageId : getCart()}))
            // }
            !serverToken && dispatch(getDefaultDataAction({ instituteId: '1' }));
        },
        [dispatch, serverToken, updateDevice],
    );

    useEffect(() => {
        let isvalue = serverToken || getLoginData();
        if (isvalue && isvalue !== 'undefined') {
            loginSuccess({ token: isvalue });
        } else {
            // setAuthenticated(false);
            setAppState(true);
        }
    }, [dispatch, serverToken, loginSuccess]);

    useEffect(() => { }, [status]);

    const toast = useToast();

    const logout = useCallback(
        (e, skipApi = false) => {
            async function logoutfun() {
                if (skipApi === false) {
                    // const response = await fetch("https://portal-auth.perceiv.io/auth/logout");
                    // console.log(response)
                    LoadingRef.current.show();
                    const res = await apis.logoutApi();
                    LoadingRef.current?.hide();

                    if (res.ok) {
                        toast({
                            status: 'success',
                            title: 'Logout Success!',
                            position: 'top',
                        });
                    }
                }

                setAuthenticated(false);
                localStorage.removeItem('@login/coco/public');
                removeCookie('coco-user');
                removeAuthorizationHeader();
                dispatch(logoutAction(skipApi));
                history.push('/');
            }
            logoutfun();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [dispatch, history, removeCookie, toast],
    );

    const getPackageContent = useCallback(
        s => {

        },
        [],
    );

    useCheckStatus({
        status,
        onSuccess: () => {
            localStorage.setItem('@login/coco/public', token.current);
            setAuthenticated(true);
            setCookie('coco-user', token.current);
            // if (!serverToken) {
            //   getPackageContent();
            // }
            // if (user && !serverToken) {
            //   dispatch(getWishlistAction());
            // }
        },
        onError: () => {
            logout();
        },
    });

    const value = useMemo(
        () => ({
            isAuthenticated,
            logout,
            loginSuccess,
            updateDevice,
            getPackageContent,
        }),
        [getPackageContent, isAuthenticated, loginSuccess, logout, updateDevice],
    );


    return (
        <AppContext.Provider value={value}>
            <Helmet>
            </Helmet>
            {children}
            <LoadingPage ref={LoadingRef} />
        </AppContext.Provider>
    );
};


export const LoadingRef = React.createRef();

const logoutRef = React.createRef();

const LoadingPage = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState('Please Wait..');
    const toast = useToast();
    const { logout, updateDevice } = useAppContext();

    useImperativeHandle(ref, () => ({
        updateDevice,
        logoutForce: m => {
            if (Date.now() - logoutRef.current > 10000) {
                toast({
                    id: 'tforcelogout',
                    title: 'Logout',
                    description:
                        'Unauthorized or you account has been logged in some other device ',
                    status: 'error',
                    position: 'top',
                    duration: 5000,
                });
                logout(null, true);
                logoutRef.current = Date.now();
            }
        },
        show: m => {
            setVisible(true);
            setMessage(m || 'Please Wait...');
        },
        hide: () => {
            setVisible(false);
            setMessage('Please Wait...');
        },
        setMessage: m => {
            setMessage(m);
        },
        showToast: config => {
            toast(config);
        },
    }));
    return (
        visible && (
            <Center
                bg="rgba(255,255,255,0.8)"
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="fixed">
                <Spinner colorScheme="telegram" size="xl" />
            </Center>
        )
    );
});
