import React, { useState } from "react";
import EditModal from "./EditModal";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import CustomAlertDialog from "./CustomAlertDialog";
import moment from "moment";

const RescheduleModule = ({
  show,
  toggleModal,
  handleEventReschedule,
  selectedEventData,
}) => {
  const toast = useToast();
  const toastId = "warning";
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [showDialog, setShowDialog] = useState(false);
  const currentDateTime = moment().format("YYYY-MM-DDTHH:mm");

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const openDialog = (e) => {
    e.preventDefault();
    if (date.startDate === "") {
      if (!toast.isActive(toastId)) {
        toast({
          title: `Please select start date!!!`,
          status: "warning",
          isClosable: true,
          position: "top",
          id: toastId,
        });
      }
      return;
    }
  
    setShowDialog(true);
  };

  const handleSubmit = (e) => {
    if(moment(date.startDate).isBefore(moment())){
      toast({
        title: `You can not select past time!`,
        status: "warning",
        isClosable: true,
        position: "top",
      });  
      return;
    }
    const startDate = moment(selectedEventData.startDate);
    const endDate = moment(selectedEventData.endDate);
    const duration = moment.duration(endDate.diff(startDate));
    const hours = duration.hours();
    const minutes = duration.minutes();


    const updatedEndDate = moment(date?.startDate)
      .add(hours, "hours")
      .add(minutes, "minutes");
    const formattedEndDate = updatedEndDate.format("YYYY-MM-DDTHH:mm");

    const dates = {
      startDate: date.startDate,
      endDate: formattedEndDate,
    };
    handleEventReschedule(dates);
    
  };
  return (
    <>
      <EditModal
        isOpen={show}
        onClose={toggleModal}
        title={"Re-Schedule Class"}
        onSubmit={openDialog}
        size={"lg"}
      >
        <FormControl>
          <FormLabel>Start Date</FormLabel>
          <Input
            size="md"
            type="datetime-local"
            name="startDate"
            onChange={handleDateChange}
            min={currentDateTime}
          />
        </FormControl>
        
      </EditModal>
      <CustomAlertDialog
        title={"Reschedule Class"}
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
        onSubmit={handleSubmit}
      >
        Are you sure? You want to Reschedule class.
      </CustomAlertDialog>
    </>
  );
};

export default RescheduleModule;
