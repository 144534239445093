import { Box, HStack, Text } from '@chakra-ui/react'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ErrorChecker } from '../Components/ErrorChecker'
import { STATUS } from '../Constants'
import { getDoubtsAction, getSingleCommonDoubtsAction, getSingleDoubtAction, readCommentAction } from '../redux/reducers/doubt'
import { DiscussionComments } from '../routes/DiscussionCommunity/DiscussionComments'
import { DoubtComponent } from './doubt'

export const SingleCommonDoubt = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const type = params.type
    const doubtId = params.id

    const {currentCommonDoubt, getSingleCommonDoubtsStatus} = useSelector(state => ({
        currentCommonDoubt:state.doubts.currentCommonDoubt,
        getSingleCommonDoubtsStatus:state.doubts.getSingleCommonDoubtsStatus
    }))

    useEffect(() => {
      dispatch(getSingleCommonDoubtsAction({commonDoubtId:doubtId}));
    }, [doubtId, dispatch])

    useEffect(() => {
        if(currentCommonDoubt)
            dispatch(readCommentAction({itemId:currentCommonDoubt.itemId}))
    }, [currentCommonDoubt, dispatch])

    
    const itemModal = currentCommonDoubt  ? currentCommonDoubt.itemModel === 'Test' || currentCommonDoubt.itemModel === 'Assignment' ? 'exam' :  
        currentCommonDoubt.itemModel === 'Video' || currentCommonDoubt.itemModel === 'Audio' ? 'content' : 'topic' : null
    return(
            <Box p={10}>
                <ErrorChecker status={getSingleCommonDoubtsStatus}>
                    {getSingleCommonDoubtsStatus === STATUS.SUCCESS ? 
                        <Box>
                            <HStack fontSize={20}>
                                <Text fontWeight={'bold'}>{currentCommonDoubt.itemModel}:</Text>
                                <Text color={'gray.500'} fontWeight='bold'>{currentCommonDoubt.itemName}</Text>
                            </HStack>
                            <br/>
                            {itemModal === 'exam' ? 
                                <DiscussionComments
                                    onBottomAtSmall = {true}
                                 
                                    commentsLabel="Comments"
                                    showAttachment={false}
                                    placeholder="Add a comment"
                                    inputStyle="flushed"
                                    itemId={currentCommonDoubt.itemId}
                                    includeDoubt={2}
                                />
                                :itemModal === 'content' ?
                                    <DiscussionComments
                                        onBottomAtSmall = {true}
                                      
                                        commentsLabel="Comments"
                                        showAttachment={false}
                                        placeholder="Add a comment"
                                        inputStyle="flushed"
                                        itemId={currentCommonDoubt.itemId}
                                        itemModel={currentCommonDoubt.itemModel}
                                        contentId={currentCommonDoubt.content}
                                        courseId={currentCommonDoubt.course}
                                        includeDoubt={2}
                                    />
                                    :
                                    <Text>Something went wrong</Text>  
                            }    
                        </Box>
                        :
                        <Text>Something went wrong</Text>
                    }
                </ErrorChecker>
            </Box>
    )
}