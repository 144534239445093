import { Badge, Box, Flex, HStack, Tag, Text, VStack } from "@chakra-ui/react";
import Pagination from "@choc-ui/paginator";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { BsArrowReturnRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { STATUS } from "../App/Constants";
import { getDoubtsAction } from "../redux/reducers/doubt";
import { bilingualText } from "../utils/Helper";

export const TeacherTests2 = ({ itemModel, openDiscussion }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const { commonDoubts, pageConfig, commonDoubtsContent, user, readCommentStatus } = useSelector(
    (state) => ({
      commonDoubts: state.doubts.commonDoubts,
      pageConfig: state.doubts.commonDoubtsPageConfig,
      commonDoubtsContent: state.doubts.commonDoubtsContent,
      user:state.user.user,
      readCommentStatus:state.doubts.readCommentStatus
    })
  );

  const filters = useMemo(() => {
    return { teacherId: params.staffId, itemModel };
  }, [params, itemModel]);

  useEffect(() => {
    if (params.staffId) 
      dispatch(getDoubtsAction(filters));
    else  
      dispatch(getDoubtsAction({...filters, teacherId:user?._id}));
      
  }, [dispatch, params, filters, user]);
  

  const handlePageChange = (page) => {
    dispatch(getDoubtsAction({ ...filters, page: page }));
  };

  const _openDoubt = (d) => {
    openDiscussion(d);
  };

  return (
    <Box>
    
      {commonDoubts?.length ? (
        _.map(commonDoubts, (d, i) => {
          return (
            <DoubtItem
              itemModel={itemModel}
              commonDoubtsContent={commonDoubtsContent}
              onPress={_openDoubt}
              d={d}
              i={i + 1 + pageConfig?.page * pageConfig?.limit - 20}
            />
          );
        })
      ) : (
        <Text color={"brand.secondary"} textAlign="center">
          No doubts available
        </Text>
      )}
      <br />
      {pageConfig ? (
        <HStack align="center">
          <Pagination
            current={pageConfig?.page}
            total={pageConfig?.total}
            pageSize={pageConfig?.limit}
            onChange={handlePageChange}
            paginationProps={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
            colorScheme="blue"
          />
        </HStack>
      ) : null}
    </Box>
  );
};

const DoubtItem = ({ onPress, d, i, itemModel, commonDoubtsContent }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user)

  const checkRole = useMemo(() => {
    return (
      user?.staff?.staffRole === "ADMIN" ||
      user?.staff?.staffRole === "HEAD_TEACHER" 
     
    );
  }, [user?.staff?.staffRole]);

  const _open = () => {
    onPress(d)
   
  };

  const readPending = useMemo(() => {
    return d.unreadCommentCount
  }, [d])

  return (
    <Flex
      onClick={_open}
      p={2}
      px={4}
      _hover={{ bg: "gray.100", boxShadow: "sm" }}
      cursor="pointer"
      border="1px solid #dddddd"
      borderRadius={"md"}
      m={1}
    >
      <Box mr={5}>
        <Text fontSize="" color="gray.700">
          {i}.
        </Text>
      </Box>
      <Box flex={1}>
        <Flex alignItems={'center'}>
          <Text fontSize="sm" fontWeight={readPending && 'bold'} color="gray.600">
            {d.lastSmsUserName}
          </Text>
          <Text color="gray.500" fontSize="xs" ml={10}>
            <i>{moment(d.createdAt).format("DD MMM YYYY hh:mm a")}</i>
          </Text>
          {readPending ?
            <Box>
              <Badge ml={4} colorScheme='green'>
                {d.unreadCommentCount} New Message
              </Badge>
            </Box>
            :
            null
          }
        </Flex>
        {d.itemModel === "Test" || d.itemModel === "Assignment" ? (
          <Flex>
            <Text color="gray.500" fontSize="xs">
              <i>{d.itemName}</i>
            </Text>
          </Flex>
        ) : d.itemModel === "Audio" || d.itemModel === "Video" ? (
          <Flex>
            <Text color="gray.500" fontSize="xs">
              <i>{d.courseName} - {d.subjectName} - {d.itemName}</i>
            </Text>
          </Flex>
        ) : null}
        {d.parentComment ? 
          <VStack spacing={0} align={'stretch'} p={1} px={4} my={2} width={'100%'} borderRadius={4} background='gray.100'>
            <Text fontSize={'xs'} color='gray.600' fontWeight={'bold'}>{d.parentComment.user.name}</Text>
            <Text fontSize={'xs'} color='gray.600'>{d.parentComment.comment}</Text>
          </VStack>
          :
          null
        }
        <HStack pb={2} pt={1} pl={d.parentComment && 4}>
          {d.parentComment && <BsArrowReturnRight/> }
          <Box fontWeight={readPending && 'bold'}>
            <Text fontSize={"sm"}>{d.lastSmsText}</Text>
          </Box>
        </HStack>
      </Box>
    </Flex>
  );
};
