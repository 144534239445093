import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { STATUS } from "../../../App/Constants";
import { apis } from "../../../services/api/apis";
import { LoadingRef } from "../../../App/AppProvider";
import { find, map, size, sumBy } from "lodash";

// Cart
export const requestUserCartAction = createAsyncThunk(
  "cart/get",
  async (payload, thunkAPI) => {
    const response = await apis.requestUserCartApi(payload);
    const { ok, problem, data } = response;
    if (ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(problem);
    }
  }
);

export const addPackageToCartAction = createAsyncThunk(
  "cart/add",
  async (payload, thunkAPI) => {
    LoadingRef.current.show();
    const response = await apis.addToUserCartApi(payload);
    LoadingRef.current.hide();
    const { ok, problem, data } = response;
    if (ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(problem);
    }
  }
);

export const verifyPromoAciton = createAsyncThunk(
  "cart/promo/verify",
  async (payload, thunkAPI) => {
    LoadingRef.current.show();
    const response = await apis.verifyPromoApi(payload);
    LoadingRef.current.hide();
    const { ok, problem, data } = response;
    if (ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(problem);
    }
  }
);

const initialState = {};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    updateCartData: (state, { payload }) => {
      if (payload.cart) {
        state.userCart = payload.cart;
      }
    },

    resetPromoCode: (state) => {
      state.promoCodeData = null;
      state.verifyPromoStatus = STATUS.NOT_STARTED;
    },
  },
  extraReducers: {
    // Cart
    [requestUserCartAction.pending]: (state, action) => {
      state.getCartStatus = STATUS.FETCHING;
    },

    [requestUserCartAction.rejected]: (state, action) => {
      state.getCartStatus = STATUS.FAILED;
    },

    [requestUserCartAction.fulfilled]: (state, action) => {
      state.getCartStatus = STATUS.SUCCESS;
      state.userCart = action.payload;
    },

    // Cart > Add
    [addPackageToCartAction.pending]: (state, action) => {
      state.getCartAddStatus = STATUS.FETCHING;
    },

    [addPackageToCartAction.rejected]: (state, action) => {
      state.getCartAddStatus = STATUS.FAILED;
    },

    [addPackageToCartAction.fulfilled]: (state, action) => {
      state.getCartAddStatus = STATUS.SUCCESS;
      state.userCart = action.payload;
    },

    [verifyPromoAciton.pending]: (state) => {
      state.verifyPromoStatus = STATUS.FETCHING;
    },

    [verifyPromoAciton.rejected]: (state) => {
      state.verifyPromoStatus = STATUS.FAILED;
    },

    [verifyPromoAciton.fulfilled]: (state, action) => {
      state.verifyPromoStatus = STATUS.SUCCESS;
      state.promoCodeData = action.payload;
    },
  },
});

export const { updateCartData, resetPromoCode } = cartSlice.actions;
export const cartReducer = cartSlice.reducer;

export const cartSelector = createSelector(
  (state) => state,
  (state) => {
    if (state.userCart?.packages) {
      let gst = 0;
      let isOffline = false;
      let cartTotal = 0;
      let fakeTotal = 0;
      let wallet = true;
      let terms = []

      const pkgmap = map(state.userCart?.packages, (pkg) => {
        if (pkg.type === 'DRIVE' || pkg.type === 'BOOK' || pkg.mode === 'offline') {
          isOffline = true;
        }

        if (pkg.type === 'MAGAZINE' && pkg.mode !== 'online') {
          isOffline = true;
        }
        
        if (!pkg.walletApplicable) {
          wallet = false;
        }

        const offer =
          pkg.offers && size(pkg.offers)
            ? find(pkg.offers, (ofr) => ofr && ofr.active)
            : undefined;

        const price = offer ? offer.price : pkg.price;
        cartTotal += price;

        const fprice = offer
          ? offer.fakePrice || offer.price
          : pkg.fakePrice || pkg.price;
        fakeTotal += fprice;


        if(pkg && (pkg.terms?.en || pkg.centers)) {
          terms.push({
            pkgName: pkg.name?.en,
            terms: pkg.terms,
            centers: pkg.centers
          })
        }
        
        return {
          price,
          _id: pkg._id,
          gst: pkg.gst,
          offerApplicable: offer ? true : false,
        };
      });

      let promoDiscount = 0;

      if (state.promoCodeData) {
        if (state.promoCodeData.type === "percent") {
          promoDiscount = Math.min(
            (state.promoCodeData.percent * cartTotal) / 100,
            state.promoCodeData.maxDiscount
          );
        } else {
          promoDiscount = Math.min(state.promoCodeData.flat, cartTotal);
        }
      }
      let availableDiscountAmount = promoDiscount;

      gst = sumBy(pkgmap, (pkg) => {
        let price = pkg.price;

        if (availableDiscountAmount) {
          if (pkg.price > availableDiscountAmount) {
            price = pkg.price - availableDiscountAmount;
            availableDiscountAmount = 0;
          } else {
            availableDiscountAmount -= price;
            price = 0;
          }
        }
        if (pkg.gst) {
          return (price * pkg.gst) / 100;
        }
        return 0;
      });

      const saved = fakeTotal - cartTotal;

      return {
        isOffline,
        total: fakeTotal.toFixed(2),
        afterDiscount: cartTotal.toFixed(2),
        initialDiscount: saved.toFixed(2),
        gst: gst ? gst.toFixed(2) : 0,
        wallet,
        terms,
        saved: (saved + promoDiscount).toFixed(2),
        promoDiscount: promoDiscount ? promoDiscount.toFixed(2) : 0,
        topay: (cartTotal - promoDiscount + gst).toFixed(2),
      };
    } else {
      return {
        isOffline: false,
        total: 0,
        afterDiscount: 0,
        initialDiscount: 0,
        gst: 0,
        wallet: true,
        saved: 0,
        promoDiscount: 0,
        topay: 0,
      };
    }
  }
);
