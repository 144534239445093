import React, { useEffect, useState } from "react";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIsAuthenticated } from "../App/Context";
import { useLoginModal } from "../App/useLoginModal";
import { addPackageToCartAction, cartSelector } from "../redux/reducers/cart";
import { unlockPackageAction } from "../redux/reducers/packages";
import CartContext from "./useCart";

export const addToCart = (item, toast) => {
  // Expect an id of the package
  let cart = getCart();
  cart.push(item);
  cart = JSON.stringify(cart);
  localStorage.setItem("userCart", cart);
  toast.closeAll();
  toast({
    title: "Item Added.",
    description: "Your item is added to The Cart.",
    status: "success",
    duration: 2000,
    isClosable: true,
  });
};

export const removeFromCart = (item, toast) => {
  // Expect an id of the package
  let cart = getCart();
  if (cart.includes(item)) {
    cart.pop(item);
  }
  cart = JSON.stringify(cart);
  localStorage.setItem("userCart", cart);
  toast.closeAll();
  toast({
    title: "Item Removed.",
    description: "Your item is removed from The Cart.",
    status: "error",
    duration: 2000,
    isClosable: true,
  });
};

export const getCart = () => {
  return typeof window === "undefined"
    ? null
    : JSON.parse(localStorage.getItem("userCart")) ?? [];
};

export const CartProvider = ({ children }) => {
  const [packagesData, setPackagesData] = useState([]);

  const { cart, packages, student } = useSelector((s) => ({
    cart: s.cart,
    packages: s.package.packagesList,
    student: s.user.student,
  }));

  const cartAnalysis = cartSelector(cart);

  useEffect(() => {
    if (packages?.length && cart.userCart) {
      const final = packages.filter((pkg) => {
        return _.find(cart.userCart.packages, (p) => p._id === pkg._id)
          ? true
          : false;
      });
      setPackagesData(final);
    } else {
      setPackagesData([]);
    }
  }, [packages, cart.userCart]);

  const isAuthenticated = useIsAuthenticated();

  const dispatch = useDispatch();

  const {toggleLoginModal} = useLoginModal()

  const addPackageToCart = useCallback(
    (pid, callback) => {
      if (isAuthenticated) {
        dispatch(addPackageToCartAction({ packageId: pid }));
        if(callback){
          callback()
        }
      }else{

        toggleLoginModal()
      }
    },
    [dispatch, isAuthenticated, toggleLoginModal]
  );

  const unlockPackage = useCallback(
    (packages, callback) => {
      if (isAuthenticated) {
        dispatch(unlockPackageAction(packages));
        if(callback){
          callback()
        }
      }else{
  
        toggleLoginModal()
      }
    },
    [dispatch, isAuthenticated, toggleLoginModal]
  )

  const updateCart = useCallback(() => {
  }, []);

  const removePackageFromCart = useCallback((pkgId) => {
    setPackagesData((p) => {
      let finalPackages = _.filter(p, (pk) => pk._id !== pkgId);
      dispatch(
        addPackageToCartAction({
          packageId: _.map(finalPackages, (pk) => pk._id),
        })
      );
      return finalPackages;
    });
  }, [dispatch]);

  const isPackageInCart = useCallback(
    (pkgId) => {
      if (packagesData?.length) {
        return _.find(packagesData, (p) => p._id === pkgId) ? true : false;
      }
    },
    [packagesData]
  );

  const isPackagePurchased = useCallback(
    (pkgId) => {
      if (student?.packages?.length) {
        return _.find(
          student?.packages,
          (p) => (p.package?._id || p.package) === pkgId
        )
          ? true
          : false;
      }
    },
    [student?.packages]
  );

  const contextValue = useMemo(
    () => ({
      cart: cart.userCart,
      unlockPackage,
      addPackageToCart,
      updateCart,
      removePackageFromCart,
      isPackageInCart,
      isPackagePurchased,
      packagesData,
      cartAnalysis,
    }),
    [cart.userCart, unlockPackage, addPackageToCart, updateCart, removePackageFromCart, isPackageInCart, isPackagePurchased, packagesData, cartAnalysis]
  );

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
};
