import React, { useState, useEffect } from "react";

import { AiOutlineCalendar } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

import { map } from "jquery";
import moment from "moment";

import { AddIcon } from "@chakra-ui/icons";
import {
    Box,
    Divider,
    Flex,
    Heading,
    HStack,
    Text,
    VStack,
    Input,
    Select,
    Button,
    ButtonGroup,
    IconButton,
} from "@chakra-ui/react";

import {
    deletePlannerEventRequestAction,
    getPlannerEventRequestListAction,
    getPlannerScheduleAction,
    getStaffsListAction,
    getUserScheduleAction,
    updatePlannerEventAction,
    updatePlannerEventRescheduleAction,
} from "../../redux/reducers/planner";

import EditModal from "./EditModal";
import SwapModule from "./SwapModule";
import AddExtraClass from "./AddExtraClass";
import RequestModule from "./RequestModule";
import RescheduleModule from "./RescheduleModule";
import CustomAlertDialog from "./CustomAlertDialog";
import { STATUS } from "../../App/Constants";
import ViewButtonModule from "./ViewButtonModule";
import JoinButtonModule from "./JoinButtonModule";
import { orderBy } from "lodash";

let scheduleIntervalTime = null;
export default function TodayClasses({ selectedDate, currentTeacherId }) {
    const dispatch = useDispatch();
    const [events, setEvents] = useState();
    const [currentDateEvents, setCurrentDateEvents] = useState();
    const [filterEvent, setFilterEvent] = useState([]);
    const [staffId, setStaffId] = useState("");
    const [showRescheduleModal, setShowRescheduleModal] = useState(false);
    const [showSwapClassModal, setShowSwapClassModal] = useState(false);
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedEventData, setSelectedEventData] = useState({});
    const [showViewButtonModal, setShowViewButtonModal] = useState(false);
    const [showJoinButtonModal, setShowJoinButtonModal] = useState(false);
    const [pendingRequests, setPendingRequests] = useState([])


    const sampleStudents = [{ id: "1", name: "Alice Johnson" }];
    const sampleTeachers = [{ id: "1", name: "Mr. Brown" }];

    const {
        user,
        activeStaffsList,
        // plannerScheduleDetails,
        plannerRequestList,
        plannerScheduleList,
        updatePlannerEventStatus,
        updatePlannerEventRescheduleStatus,
        deletePlannerEventRequestListStatus,
        getPlannerScheduleListStatus,
        // getPlannerScheduleStatus,
    } = useSelector((s) => ({
        user: s.user.user,
        activeStaffsList: s.planner.activeStaffsList,
        plannerRequestList: s.planner.plannerRequestList,
        plannerScheduleList: s.planner.plannerScheduleList,
        // plannerScheduleDetails: s.planner.plannerScheduleDetails,
        updatePlannerEventStatus: s.planner.updatePlannerEventStatus,
        // getPlannerScheduleStatus: s.planner.getPlannerScheduleStatus,
        getPlannerScheduleListStatus: s.planner.getPlannerScheduleListStatus,
        deletePlannerEventRequestListStatus:
            s.planner.deletePlannerEventRequestListStatus,
        updatePlannerEventRescheduleStatus:
            s.planner.updatePlannerEventRescheduleStatus,
    }));

    useEffect(() => {
        if (selectedDate) {
            if (plannerScheduleList && plannerScheduleList.length > 0) {
                setEvents(plannerScheduleList);
            } else {
                setEvents([]);
            }

            const packageFilter = document.getElementById("packageFilter");
            if (packageFilter) {
                packageFilter.value = "";
            }

            dispatch(getStaffsListAction({ id: "65b51fbc2ff7b4790f01e91e" }));
        }
    }, [selectedDate, plannerScheduleList]);

    useEffect(() => {
        if (events && events.length > 0) {
            const selectedEvents = events.filter(
                (event) =>
                    moment(selectedDate).format("YYYY-MM-DD") ===
                    moment(event.startDate).format("YYYY-MM-DD")
            );
            setCurrentDateEvents(selectedEvents);
        } else {
            setCurrentDateEvents([]);
        }
    }, [events, selectedDate]);

    useEffect(() => {
        if (currentDateEvents?.length > 0) {
            const sortedEvents = orderBy(
                currentDateEvents,
                [(event) => moment(event?.startDate).toDate()],
                ["asc"]
            );
            setFilterEvent(sortedEvents);
        } else {
            setFilterEvent([]);
        }
    }, [currentDateEvents]);

    useEffect(() => {
        if (updatePlannerEventStatus === STATUS.SUCCESS) {
            setShowRescheduleModal(false);
            setShowSwapClassModal(false);
            setShowCancelModal(false);
            dispatch(
                getPlannerScheduleAction({
                    staffIds: [user?.staff?._id],
                    date: moment(selectedDate).format("YYYY-MM-DD"),
                })
            );
            // dispatch(getUserScheduleAction({ teacherId: user?._id }));
        }
    }, [updatePlannerEventStatus]);

    useEffect(() => {
        if (getPlannerScheduleListStatus === STATUS.SUCCESS) {
            dispatch(getPlannerEventRequestListAction());
        }
    }, [getPlannerScheduleListStatus]);

    useEffect(() => {
        if (plannerRequestList) {
            const requestSend = plannerRequestList.requestSend.filter(reqSend => reqSend.rescheduleStatus === "Pending")
            const requestRecive = plannerRequestList.requestRecive.filter(reqRecive => reqRecive.rescheduleStatus === "Pending")
            setPendingRequests({
                requestRecive: requestRecive,
                requestSend: requestSend
            })
        }
    }, [plannerRequestList])


    useEffect(() => {
        scheduleIntervalTime = setInterval(() => {
            dispatch(
                getPlannerScheduleAction({
                    staffIds: [user?.staff?._id],
                    date: moment(selectedDate).format("YYYY-MM-DD"),
                })
            );
        }, 60000);
        return () => {
            if (scheduleIntervalTime) {
                clearInterval(scheduleIntervalTime);
            }
        }
    }, []);

    useEffect(() => {
        if (deletePlannerEventRequestListStatus === STATUS.SUCCESS) {
            dispatch(getPlannerEventRequestListAction());
            dispatch(
                getPlannerScheduleAction({
                    staffIds: [user?.staff?._id],
                    date: moment(selectedDate).format("YYYY-MM-DD"),
                })
            );
           
        } else if (updatePlannerEventRescheduleStatus === STATUS.SUCCESS) {
            dispatch(getPlannerEventRequestListAction());
            dispatch(
                getPlannerScheduleAction({
                    staffIds: [user?.staff?._id],
                    date: moment(selectedDate).format("YYYY-MM-DD"),
                })
            );
         
        }
    }, [deletePlannerEventRequestListStatus, updatePlannerEventRescheduleStatus]);

    const handlePackageSearchChange = (e) => {
        const { value, type } = e.target;
        if (value.length >= 3) {
            const _filterEvents = currentDateEvents.filter((event) => {
                return event.name.toLowerCase().includes(value.toLowerCase());
            });
            setFilterEvent(_filterEvents);
        } else {
            setFilterEvent(currentDateEvents);
        }
    };

    const handleEventCancel = () => {
        const data = {
            eventId: selectedEventData?._id,
            rescheduleBy: selectedEventData?.staff[0]?.staffId?._id,
            rescheduleType: "cancel",
        };
       
        dispatch(updatePlannerEventAction(data));
       
    };

    const handleEventSwap = (otherMentorData) => {
        const data = {
            eventId: selectedEventData?._id,
            rescheduleBy: selectedEventData?.staff[0]?.staffId?._id,
            swappedEventId: otherMentorData?.eventId,
            rescheduleWith: otherMentorData?.staffId,
            rescheduleType: "swapped",
        };
        dispatch(updatePlannerEventAction(data));
        
    };

    const handleEventReschedule = (dates) => {
        const data = {
            eventId: selectedEventData?._id,
            startDate: dates.startDate,
            endDate: dates.endDate,
            rescheduleBy: selectedEventData?.staff[0]?.staffId?._id,
            rescheduleType: "reschedule",
        };
        dispatch(updatePlannerEventAction(data));
      
    };

    const handleRequestsList = () => {
        dispatch(getPlannerEventRequestListAction());
        setShowRequestModal(true);
    };

    const handleViewButton = () => {
        setShowViewButtonModal(true);
    };
    const handleJoinButton = () => {
        setShowJoinButtonModal(true);
    };
    const closeModal = () => {
        setShowViewButtonModal(false);
        setShowJoinButtonModal(false);
    };

    const _handleRequest = (request, status) => {
      
        dispatch(
            updatePlannerEventRescheduleAction({
                _id: request?._id,
                status: status === "approve" ? true : false,
            })
        );
    };

    const _deleteRequest = (request) => {
        dispatch(deletePlannerEventRequestAction({ _id: request?._id }));
    };

    return (
        <>
            <Box w="50%" px={[1, 1, 1, 5]}>
                <Box
                    className="overflow-auto text-center events-heading-block"
                    py={2}
                    px={1}
                    style={{ height: "max-content" }}
                    borderRadius={5}
                    boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                >
                    {" "}
                    SCHEDULE
                </Box>
                <Box>
                    <div className="today-events-block">
                        {filterEvent && filterEvent?.length > 0 ? (
                            map(filterEvent, (currEvent, index) => {
                                const startDate = moment(currEvent?.startDate);
                                const endDate = moment(currEvent?.endDate);
                                const currentDateTime = moment();
                                const isExactMatch = startDate.isSame(
                                    currentDateTime,
                                    "minute"
                                );
                                const isBetweenStartAndEnd = currentDateTime.isBetween(
                                    startDate,
                                    endDate,
                                    "minute",
                                    "[]"
                                );
                                const isPast = endDate.isBefore(currentDateTime, "minute");
                                const isFuture = startDate.isAfter(currentDateTime, "minute");
                                const isPending =
                                    currEvent?.rescheduleRequest === "Pending" ||
                                    currEvent?.classStatus === "Cancelled";
                                return currEvent?.classStatus === "Rescheduled" ? (
                                    <div className="event-card-block reschedule-class">
                                        <div className="event-card">
                                            <div className="classes-card">
                                                <div className="course-heading">
                                                    <h4>
                                                        {" "}
                                                        {currEvent?.subjectId?.name?.en} –{" "}
                                                        {currEvent?.topic}.
                                                    </h4>
                                                    <p>{currEvent?.chapter?.name?.en}</p>
                                                </div>
                                                <div className="status-tag">
                                                    <label> Rescheduled </label>
                                                </div>
                                                <div className="swapped-member-sec">
                                                    <div className="swapped-whith-sec">
                                                        <div
                                                            className="swapped-section"
                                                            style={{ color: "#db006f", fontWeight: "600" }}
                                                        >
                                                            {" "}
                                                            Rescheduled to:
                                                        </div>
                                                        <div className="swapped-section mt-1">
                                                            <div className="swapped-date-sec ">
                                                                <p>
                                                                    Date:{" "}
                                                                    {moment(
                                                                        currEvent?.rescheduleEventId?.startDate
                                                                    ).format("DD-MMM-YYYY")}
                                                                </p>
                                                                <p>
                                                                    Time:
                                                                    {`${moment(
                                                                        currEvent?.rescheduleEventId?.startDate
                                                                    ).format("HH:mm")} - ${moment(
                                                                        currEvent?.rescheduleEventId?.endDate
                                                                    ).format("HH:mm")}`}{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="date-time-sec">
                                                <div className="date-sec">
                                                    <span>Assigned Date:</span>
                                                    <p>
                                                        {moment(currEvent?.startDate).format("DD-MMM-YYYY")}
                                                    </p>
                                                </div>
                                                <div className="time-sec">
                                                    <span>Assigned Time:</span>
                                                    <p>
                                                        {moment(currEvent?.startDate).format("HH:mm")} -{" "}
                                                        {moment(currEvent?.endDate).format("HH:mm")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : currEvent?.classStatus === "Swapped" ? (
                                    <div className="event-card-block swapped-class">
                                        <div className="event-card">
                                            <div className="classes-card">
                                                <div className="course-heading">
                                                    <h4>
                                                        {" "}
                                                        {currEvent?.subjectId?.name?.en} –{" "}
                                                        {currEvent?.topic}.
                                                    </h4>
                                                    <p>{currEvent?.chapter?.name?.en}</p>
                                                </div>
                                                <div className="status-tag">
                                                    <label> Swapped </label>
                                                </div>
                                                <div className="swapped-member-sec">
                                                    <div className="swapped-whith-sec">
                                                        <p>Swapped with:</p>
                                                        <div className="swapped-section">
                                                            <div className="swapped-user">
                                                                <img
                                                                    src={
                                                                        currEvent?.rescheduleEventId?.staff[0]
                                                                            ?.staffId?.user?.avatar
                                                                    }
                                                                    class="chakra-avatar__img css-9r7v3q"
                                                                ></img>
                                                            </div>
                                                            <div className="swapped-date-sec">
                                                                <label>
                                                                    {
                                                                        currEvent?.rescheduleEventId?.staff[0]
                                                                            ?.staffId?.user?.name
                                                                    }
                                                                </label>
                                                                <p>
                                                                    Date:{" "}
                                                                    {moment(
                                                                        currEvent?.rescheduleEventId?.startDate
                                                                    ).format("DD-MMM-YYYY")}
                                                                </p>
                                                                <p>
                                                                    Time:{" "}
                                                                    {`${moment(
                                                                        currEvent?.rescheduleEventId?.startDate
                                                                    ).format("HH:mm")} - ${moment(
                                                                        currEvent?.rescheduleEventId?.endDate
                                                                    ).format("HH:mm")}`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="date-time-sec">
                                                <div className="date-sec">
                                                    <span>Assigned Date:</span>
                                                    <p>
                                                        {moment(currEvent?.startDate).format("DD-MMM-YYYY")}
                                                    </p>
                                                </div>
                                                <div className="time-sec">
                                                    <span>Assigned Time:</span>
                                                    <p>{`${moment(currEvent?.startDate).format(
                                                        "HH:mm"
                                                    )} - ${moment(currEvent?.endDate).format(
                                                        "HH:mm"
                                                    )}`}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : currEvent?.classStatus === "Cancelled" ? (
                                    <div className="event-card-block cancel-class">
                                        <div className="event-card">
                                            <div className="classes-card">
                                                <div className="course-heading">
                                                    <h4>
                                                        {" "}
                                                        {currEvent?.subjectId?.name?.en} –{" "}
                                                        {currEvent?.topic}.
                                                    </h4>
                                                    <p>{currEvent?.chapter?.name?.en}</p>
                                                </div>
                                                <div className="status-tag">
                                                    <label> Canceled</label>
                                                </div>
                                            </div>
                                            <div className="date-time-sec">
                                                <div className="date-sec">
                                                    <span>Assigned Date:</span>
                                                    <p>
                                                        {moment(currEvent?.startDate).format("DD-MMM-YYYY")}
                                                    </p>
                                                </div>
                                                <div className="time-sec">
                                                    <span>Assigned Time:</span>
                                                    <p>{`${moment(currEvent?.startDate).format(
                                                        "HH:mm"
                                                    )} - ${moment(currEvent?.endDate).format(
                                                        "HH:mm"
                                                    )}`}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : currEvent?.type === "class" ||
                                    (currEvent?.type === "extraClass" &&
                                        !currEvent?.classStatus !== "Swapped" &&
                                        currEvent?.classStatus !== "Rescheduled") ? (
                                    <div className="event-card-block" key={index}>
                                        <div className="event-card">
                                            <div className="classes-card">
                                                <div className="course-heading">
                                                    <h4>
                                                        {" "}
                                                        {currEvent?.subjectId?.name?.en} –{" "}
                                                        {currEvent?.topic}.
                                                    </h4>
                                                    <p>{currEvent?.chapter?.name?.en}</p>
                                                    <p>
                                                        {currEvent?.type === "class"
                                                            ? "Regular Class"
                                                            : "Extra Class"}
                                                    </p>
                                                </div>
                                                {currEvent?.classStatus === "Cancelled" && (
                                                    <div className="status-tag">
                                                        <label> {currEvent?.classStatus}</label>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="date-time-sec">
                                                <div className="date-sec">
                                                    <span>Assigned Date:</span>
                                                    <p>
                                                        {moment(currEvent?.startDate).format("DD-MMM-YYYY")}
                                                    </p>
                                                </div>
                                                <div className="time-sec">
                                                    <span>Assigned Time:</span>
                                                    <p>{`${moment(currEvent?.startDate).format(
                                                        "HH:mm"
                                                    )} - ${moment(currEvent?.endDate).format(
                                                        "HH:mm"
                                                    )}`}</p>
                                                </div>
                                            </div>
                                            {currEvent?.rescheduleRequest === "Pending" ||
                                                !isFuture ||
                                                currEvent?.eventStatus === false ? (
                                                <></>
                                            ) : (
                                                <div className="buttons-block">
                                                    <div className="cancel-but">
                                                        <button
                                                            type="button"
                                                            disabled={isPending}
                                                            onClick={() => {
                                                                setSelectedEventData(currEvent);
                                                                setShowCancelModal(true);
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                    <div className="reschedule-but">
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                setSelectedEventData(currEvent);
                                                                setShowRescheduleModal(true);
                                                            }}
                                                        >
                                                            Reschedule
                                                        </button>
                                                    </div>
                                                    {currEvent?.type === "class" && (
                                                        <div className="swap-but">
                                                            <button
                                                                type="button"
                                                                disabled={isPending}
                                                                onClick={() => {
                                                                    setSelectedEventData(currEvent);
                                                                    setShowSwapClassModal(true);
                                                                }}
                                                            >
                                                                Swap
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (

                                    <></>
                                );
                            })
                        ) : (
                            <div className="text-center"> No events available.</div>
                        )}

                       
                    </div>
                </Box>
            </Box>
            <Box w="50%" px={[1, 1, 1, 5]}>
                <Box
                    className="overflow-auto text-center events-heading-block"
                    py={2}
                    px={1}
                    style={{ height: "max-content", textAlign: "center" }}
                    borderRadius={5}
                    boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                >
                    REQUEST
                </Box>
                <div className="today-events-block">
                    {(pendingRequests?.requestRecive?.length === 0 && pendingRequests?.requestSend?.length === 0) ? <div className="text-center"> No requests available.</div> : (<>
                        {plannerRequestList && plannerRequestList?.requestRecive.length > 0 ? (
                            plannerRequestList?.requestRecive?.map((request, i) => {
                                const startDate = moment(request?.eventId?.startDate).format(
                                    "hh:mm a"
                                );
                                const endDate = moment(request?.eventId?.endDate).format(
                                    "hh:mm a"
                                );
                                return request?.rescheduleStatus === "Approved" ? (
                                    request?.eventId?.classStatus === "Cancelled" ? (
                                        <></>
                                    ) : (
                                      
                                       
                                        <></>
                                    )
                                ) : request?.rescheduleStatus === "Pending" ? (
                                    <div className="event-card-block progress-class">
                                        <div className="event-card">
                                            <div className="classes-card">
                                                <div className="course-heading">
                                                    <h4>
                                                        {" "}
                                                        {request?.swappedEventId?.subjectId?.name?.en} –{" "}
                                                        {request?.swappedEventId?.topic}
                                                    </h4>
                                                </div>
                                                <div className="status-tag">
                                                    <label>
                                                        {" "}
                                                        {request?.rescheduleType === "swapped"
                                                            ? "swap"
                                                            : request?.rescheduleType}
                                                    </label>
                                                </div>
                                                <div className="swapped-member-sec">
                                                    <div className="swapped-whith-sec">
                                                        <p style={{ color: "#ff832e" }}>Swap with:</p>
                                                        <div className="swapped-section">
                                                            <div className="swapped-user">
                                                                <img
                                                                    src={request?.rescheduleBy?.user?.avatar}
                                                                    class="chakra-avatar__img css-9r7v3q"
                                                                ></img>
                                                            </div>
                                                            <div className="swapped-date-sec">
                                                                <label style={{ color: "#ff832e" }}>
                                                                    {request?.rescheduleBy?.user?.name}
                                                                </label>
                                                                <p style={{ color: "#ff832e" }}>
                                                                    Date:{" "}
                                                                    {moment(request?.eventId?.startDate).format(
                                                                        "DD-MMM-YYYY"
                                                                    )}
                                                                </p>
                                                                <p style={{ color: "#ff832e" }}>
                                                                    Time:{" "}
                                                                    {`${moment(request?.eventId?.startDate).format(
                                                                        "HH:mm"
                                                                    )} - ${moment(request?.eventId?.endDate).format(
                                                                        "HH:mm"
                                                                    )}`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="date-time-sec">
                                                <div className="date-sec">
                                                    <span>Assigned Date:</span>
                                                    <p>
                                                        {moment(request?.swappedEventId?.startDate).format(
                                                            "DD-MMM-YYYY"
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="time-sec">
                                                    <span>Assigned Time:</span>
                                                    <p>{`${moment(
                                                        request?.swappedEventId?.startDate
                                                    ).format("HH:mm")} - ${moment(
                                                        request?.swappedEventId?.endDate
                                                    ).format("HH:mm")}`}</p>
                                                </div>
                                            </div>
                                            {request?.approvedbyMentor === false ? (
                                                <div className="buttons-block">
                                                    <div className="cancel-but">
                                                        <button
                                                            type="button"
                                                         
                                                            onClick={() => {
                                                                _handleRequest(request, "approve");
                                                            }}
                                                        >
                                                            Accept
                                                        </button>
                                                    </div>
                                                    <div className="cancel-but">
                                                        <button
                                                            type="button"
                                                         
                                                            onClick={() => {
                                                                _handleRequest(request, "cancel");
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                );
                            })
                        ) : (
                            <></>
                        )}
                        {plannerRequestList && plannerRequestList?.requestSend?.length > 0 ? (
                            plannerRequestList?.requestSend?.map((request, i) => {
                                const startDate = moment(request?.eventId?.startDate).format(
                                    "hh:mm a"
                                );
                                const endDate = moment(request?.eventId?.endDate).format(
                                    "hh:mm a"
                                );
                                return request?.rescheduleStatus === "Approved" ? (
                                    <></>
                                ) : 

                                    request?.rescheduleStatus === "Pending" ? (
                                        <div className="event-card-block progress-class">
                                            <div className="event-card">
                                                <div className="classes-card">
                                                    <div className="course-heading">
                                                        <h4>
                                                            {" "}
                                                            {request?.eventId?.subjectId?.name?.en} –{" "}
                                                            {request?.eventId?.topic}
                                                        </h4>
                                                    </div>
                                                    <div className="status-tag">
                                                        <label>
                                                            {" "}
                                                            {request?.rescheduleType === "swapped"
                                                                ? "swap"
                                                                : request?.rescheduleType}
                                                        </label>
                                                    </div>
                                                    {request?.rescheduleType === "reschedule" ? (
                                                        <div className="swapped-member-sec">
                                                            <div className="swapped-whith-sec">
                                                                <div
                                                                    className="swapped-section"
                                                                    style={{ color: "#ff6800", fontWeight: 600 }}
                                                                >
                                                                    reschedule to:
                                                                </div>
                                                                <div className="swapped-section mt-1">
                                                                    <div className="swapped-date-sec">
                                                                        <p style={{ color: "#ff6800" }}>
                                                                            Date:
                                                                            {moment(request?.startDate).format(
                                                                                "DD-MMM-YYYY"
                                                                            )}
                                                                        </p>
                                                                        <p style={{ color: "#ff6800" }}>
                                                                            Time:
                                                                            {`${moment(request?.startDate).format(
                                                                                "HH:mm"
                                                                            )} - ${moment(request?.endDate).format(
                                                                                "HH:mm"
                                                                            )}`}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        request?.rescheduleType === "swapped" && (
                                                            <div className="swapped-member-sec">
                                                                <div className="swapped-whith-sec">
                                                                    <p style={{ color: "#ff832e" }}>Swap with:</p>
                                                                    <div className="swapped-section">
                                                                        <div className="swapped-user">
                                                                            <img
                                                                                src={request?.rescheduleWith?.user?.avatar}
                                                                                class="chakra-avatar__img css-9r7v3q"
                                                                            ></img>
                                                                        </div>
                                                                        <div className="swapped-date-sec">
                                                                            <label style={{ color: "#ff832e" }}>
                                                                                {request?.rescheduleWith?.user?.name}
                                                                            </label>
                                                                            <p style={{ color: "#ff832e" }}>
                                                                                Date:{" "}
                                                                                {moment(
                                                                                    request?.swappedEventId?.startDate
                                                                                ).format("DD-MMM-YYYY")}
                                                                            </p>
                                                                            <p style={{ color: "#ff832e" }}>
                                                                                Time:{" "}
                                                                                {`${moment(
                                                                                    request?.swappedEventId?.startDate
                                                                                ).format("HH:mm")} - ${moment(
                                                                                    request?.swappedEventId?.endDate
                                                                                ).format("HH:mm")}`}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                                <div className="date-time-sec">
                                                    <div className="date-sec">
                                                        <span>Assigned Date:</span>
                                                        {request?.rescheduleType === "reschedule" ? (
                                                            <p>
                                                                {" "}
                                                                {moment(request?.eventId?.startDate).format(
                                                                    "DD-MMM-YYYY"
                                                                )}
                                                            </p>
                                                        ) : request?.rescheduleType === "swapped" ? (
                                                            <p>
                                                                {moment(request?.eventId?.startDate).format(
                                                                    "DD-MMM-YYYY"
                                                                )}
                                                            </p>
                                                        ) : request?.rescheduleType === "cancel" ? (
                                                            <p>
                                                                {moment(request?.eventId?.startDate).format(
                                                                    "DD-MMM-YYYY"
                                                                )}
                                                            </p>
                                                        ) : request?.rescheduleType === "extraClass" ? (
                                                            <p>
                                                                {moment(request?.eventId?.startDate).format(
                                                                    "DD-MMM-YYYY"
                                                                )}
                                                            </p>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                    <div className="time-sec">
                                                        <span>Assigned Time:</span>
                                                        {request?.rescheduleType === "reschedule" ? (
                                                            <p>
                                                                {" "}
                                                                {moment(request?.eventId?.startDate).format(
                                                                    "HH:mm"
                                                                )}{" "}
                                                                -{" "}
                                                                {moment(request?.eventId?.endDate).format("HH:mm")}
                                                            </p>
                                                        ) : request?.rescheduleType === "swapped" ? (
                                                            <p>{`${moment(request?.eventId?.startDate).format(
                                                                "HH:mm"
                                                            )} - ${moment(request?.eventId?.endDate).format(
                                                                "HH:mm"
                                                            )}`}</p>
                                                        ) : request?.rescheduleType === "cancel" ? (
                                                            <p>{`${moment(request?.eventId?.startDate).format(
                                                                "HH:mm"
                                                            )} - ${moment(request?.eventId?.endDate).format(
                                                                "HH:mm"
                                                            )}`}</p>
                                                        ) : request?.rescheduleType === "extraClass" ? (
                                                            <p>{`${moment(request?.eventId?.startDate).format(
                                                                "HH:mm"
                                                            )} - ${moment(request?.eventId?.endDate).format(
                                                                "HH:mm"
                                                            )}`}</p>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="buttons-block">
                                                    <div className="cancel-but">
                                                        <button
                                                            type="button"
                                                         
                                                            onClick={() => {
                                                                _deleteRequest(request);
                                                            }}
                                                        >
                                                            Revoke
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    );
                            })
                        ) : (
                            <></>
                        )}
                    </>)}


                    
                </div>
            </Box>

            {showRescheduleModal && (
                <RescheduleModule
                    show={showRescheduleModal}
                    toggleModal={() => setShowRescheduleModal(false)}
                    handleEventReschedule={handleEventReschedule}
                    selectedEventData={selectedEventData}
                />
            )}
            {showSwapClassModal && (
                <SwapModule
                    show={showSwapClassModal}
                    filterEventId={selectedEventData._id}
                    toggleModal={() => setShowSwapClassModal(false)}
                    activeStaffsList={activeStaffsList}
                    handleEventSwap={(otherMentorData) =>
                        handleEventSwap(otherMentorData)
                    }
                />
            )}

            {showRequestModal && (
                <RequestModule
                    show={showRequestModal}
                    toggleModal={() => setShowRequestModal(false)}
                    selectedDate={selectedDate}
                />
            )}
            {showViewButtonModal && (
                <ViewButtonModule
                    isOpen={showViewButtonModal}
                    onRequestClose={closeModal}
                    students={sampleStudents}
                    teachers={sampleTeachers}
                />
            )}
            {showJoinButtonModal && (
                <JoinButtonModule
                    isOpen={showJoinButtonModal}
                    onRequestClose={closeModal}
                />
            )}
            <CustomAlertDialog
                title={"Cancel Class"}
                isOpen={showCancelModal}
                onClose={() => setShowCancelModal(false)}
                onSubmit={handleEventCancel}
            >
                Are you sure? You want to cancel class.
            </CustomAlertDialog>
        </>
    );
}
