import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react';
import React, { useRef } from 'react'

const CustomAlertDialog = ({ isOpen, onClose, children, title, onSubmit, isOkay }) => {
    const cancelRef = useRef();
    return (
        <AlertDialog
            motionPreset='slideInBottom'
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered
        >
            <AlertDialogOverlay />

            <AlertDialogContent>
                <AlertDialogHeader>{title}</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                    {children}
                </AlertDialogBody>
                <AlertDialogFooter>

                    {
                        isOkay
                            ? <Button colorScheme='blue' ml={3} onClick={onSubmit}>
                                Okay
                            </Button>
                            : <>
                                <Button ref={cancelRef} onClick={onClose}>
                                    No
                                </Button>
                                <Button colorScheme='red' ml={3} onClick={onSubmit}>
                                    Yes
                                </Button>
                            </>
                    }
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default CustomAlertDialog