import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { LoadingRef } from "../../../App/AppProvider";
import { STATUS } from "../../../App/Constants";
import { apis } from "../../../services/api/apis";
import { isSSR } from "../../../utils/ssrHelper";

export const requestUserProfileAction = createAsyncThunk(
	"user/me",
	async (payload, thunkAPI) => {
		const response = await apis.requestUserApi(payload);
		const { ok, problem, data } = response;
		if (ok) {
			if (data.staff && (data.staff.staffRole === "TEACHER" || data.staff.staffRole === "HEAD_TEACHER" || data.staff.staffRole === "ADMIN" || data.staff.staffRole === 'SUPPORT_EXECUTIVE')) {
				return data;
			}
			else {
				LoadingRef.current.showToast({
					status: "error",
					title: "Not Authorized"
				})
				return thunkAPI.rejectWithValue("Not Authorized");

			}
		} else {
			return thunkAPI.rejectWithValue(problem);
		}
	}
);

export const updateUserDevice = createAsyncThunk(
	'user/device/post',
	async (payload, thunkAPI) => {
		const user = thunkAPI.getState()?.user?.user
		if (!user) {
			console.log({ "No User": true })
			return
		}
		const final = payload
		const response = await apis.updateUserDevice(final);
		const { ok, problem, data } = response;
		if (ok) {
			return data;
		} else {
			return thunkAPI.rejectWithValue(problem);
		}
	},
);

export const validateGoogleAccountAction = createAsyncThunk(
	"user/me",
	async (payload, thunkAPI) => {
		const response = await apis.requestUserApi();
		const { ok, problem, data } = response;
		if (ok) {
			return data;
		} else {
			return thunkAPI.rejectWithValue(problem);
		}
	}
);

export const sendOtpRequestAction = createAsyncThunk(
	"user/sendOtp",
	async (payload, thunkAPI) => {
		const response = await apis.requestOtpApi(payload);
		const { ok, problem, data } = response;
		if (ok) {
			return data;
		} else {
			LoadingRef.current.showToast({
				status: "error",
				title: "Error",
				description: data.message
			})
			return thunkAPI.rejectWithValue(problem);
		}
	}
);

export const verifyOtpRequestAction = createAsyncThunk(
	"user/verifyOtp",
	async (payload, thunkAPI) => {
		const response = await apis.verifyOtpApi(payload);
		const { ok, problem, data } = response;
		if (ok) {
			return data;
		} else {
			return thunkAPI.rejectWithValue(problem);
		}
	}
);



export const verifyCouponCodeAction = createAsyncThunk(
	"user/verifyCoupon",
	async (payload, thunkAPI) => {
		const response = await apis.verifyCouponCodeApi(payload);
		const { ok, problem, data } = response;
		if (ok) {
			return data;
		} else {
			return thunkAPI.rejectWithValue(problem);
		}
	}
);

export const updateUserAction = createAsyncThunk(
	"user/updateUser",
	async (payload, thunkAPI) => {
		const response = await apis.updateUserApi(payload);
		const { ok, problem, data } = response;
		if (ok) {
			return data;
		} else {
			return thunkAPI.rejectWithValue(problem);
		}
	}
);

export const updateUserProfileAction = createAsyncThunk(
	"settings/user/profile",
	async (payload, thunkAPI) => {
		const response = await apis.updateUserProfileApi(payload);
		const { ok, problem, data } = response;
		if (ok) {
			return data;
		} else {
			return thunkAPI.rejectWithValue(data);
		}
	}
);

export const updateAvatarAction = createAsyncThunk(
	"settings/user/avatar",
	async (payload, thunkAPI) => {
		const response = await apis.updateAvatarApi(payload);
		const { ok, problem, data } = response;
		if (ok) {
			return data;
		} else {
			return thunkAPI.rejectWithValue(problem);
		}
	}
);


const initialState = {
	status: STATUS.NOT_STARTED,
	addEducationStatus: STATUS.NOT_STARTED,
	deleteEducationStatus: STATUS.NOT_STARTED,
	addExperienceStatus: STATUS.NOT_STARTED,
	deleteExperienceStatus: STATUS.NOT_STARTED,
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		logoutAction: (state, action) => {

		},
		setStudentData: (state, { payload }) => {
			state.student = payload.student;
		},
		resetLoginStatus: (state, action) => {
			state.verifyOtpStatus = STATUS.NOT_STARTED;
			state.otpStatus = STATUS.NOT_STARTED;
			state.verifyCouponStatus = STATUS.NOT_STARTED;
		},
		resetEducationStatusAction: (state, action) => {
			state.addEducationStatus = STATUS.NOT_STARTED;
			state.deleteEducationStatus = STATUS.NOT_STARTED;
		},
		resetExperienceStatusAction: (state, action) => {
			state.addExperienceStatus = STATUS.NOT_STARTED;
			state.deleteExperienceStatus = STATUS.NOT_STARTED;
		},
		resetUserStatusAction: (state, action) => {
			state.updateUserStatus = STATUS.NOT_STARTED;
		},
	},
	extraReducers: {


		[requestUserProfileAction.pending]: (state, action) => {
			state.status = STATUS.FETCHING;
		},

		[requestUserProfileAction.rejected]: (state, action) => {
			state.status = STATUS.FAILED;
		},

		[requestUserProfileAction.fulfilled]: (state, action) => {
			state.status = STATUS.SUCCESS;
			state.user = action.payload;
			state.student = action.payload?.student;
		},

		[sendOtpRequestAction.pending]: (state, action) => {
			state.otpStatus = STATUS.FETCHING;
		},

		[sendOtpRequestAction.rejected]: (state, action) => {
			state.otpStatus = STATUS.FAILED;
		},

		[sendOtpRequestAction.fulfilled]: (state, action) => {
			state.otpStatus = STATUS.SUCCESS;
			state.otpResponse = action.payload;
		},

		[verifyOtpRequestAction.pending]: (state, action) => {
			state.verifyOtpStatus = STATUS.FETCHING;
		},

		[verifyOtpRequestAction.rejected]: (state, action) => {
			state.verifyOtpStatus = STATUS.FAILED;
		},

		[verifyOtpRequestAction.fulfilled]: (state, action) => {
			state.verifyOtpStatus = STATUS.SUCCESS;
			state.verifyOtpResponse = action.payload;
		},

		[verifyCouponCodeAction.pending]: (state, action) => {
			state.verifyCouponStatus = STATUS.FETCHING;
		},

		[verifyCouponCodeAction.rejected]: (state, action) => {
			state.verifyCouponStatus = STATUS.FAILED;
		},

		[verifyCouponCodeAction.fulfilled]: (state, action) => {
			state.verifyCouponStatus = STATUS.SUCCESS;
			state.verifyCouponResponse = action.payload;
		},


		[updateUserProfileAction.pending]: (state, action) => {
			state.updateUserStatus = STATUS.FETCHING;
		},

		[updateUserProfileAction.rejected]: (state, action) => {
			state.updateUserStatus = STATUS.FAILED;
			if (action.payload.statusCode === 406) {
				state.errorMessage = action.payload.message
			}
		},

		[updateUserProfileAction.fulfilled]: (state, action) => {
			state.updateUserStatus = STATUS.SUCCESS;
			state.user = Object.assign({}, action.payload);
		},

		[updateUserAction.pending]: (state, action) => {
			state.updateUserStatus = STATUS.FETCHING;
		},

		[updateUserAction.rejected]: (state, action) => {
			state.updateUserStatus = STATUS.FAILED;
		},

		[updateUserAction.fulfilled]: (state, action) => {
			state.updateUserStatus = STATUS.SUCCESS;
			state.user = Object.assign({}, action.payload);
		},





		[updateAvatarAction.pending]: (state, action) => {
			state.updateAvatarStatus = STATUS.FETCHING;
		},

		[updateAvatarAction.rejected]: (state, action) => {
			state.updateAvatarStatus = STATUS.FAILED;
		},

		[updateAvatarAction.fulfilled]: (state, action) => {
			state.updateAvatarStatus = STATUS.SUCCESS;
			state.user.avatar = action.payload.avatar;
		},

	},
});

export const {
	logoutAction,
	setStudentData,
	resetLoginStatus,
	resetEducationStatusAction,
	resetExperienceStatusAction,
	resetUserStatusAction,
} = userSlice.actions;
export const userReducer = userSlice.reducer;
