import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import _, { map } from "lodash";
import { Button, Flex, Input, } from "@chakra-ui/react";
import { Box, Heading } from "@chakra-ui/layout";

import { getPlannerEventRequestListAction, getPlannerScheduleAction, getUserScheduleAction } from "../../redux/reducers/planner";
import TodayClasses from "./TodayClasses";
import { PlusSquareIcon } from "@chakra-ui/icons";
import AddExtraClass from "./AddExtraClass";

const weekDays = ["S", "M", "T", "W", "T", "F", "S"];

export default function TeacherScheduleScreen() {

    const [selectedDate, setSelectedDate] = useState(moment());
    const [currentTeacherId, setCurrentTeacherId] = useState("");
    const [showExtraClassModal, setShowExtraClassModal] = useState(false);

    const currTeacherId = (id) => {
        setCurrentTeacherId(id)
    }
    useEffect(() => {
        if (selectedDate) {

        }
    }, [selectedDate]);
    
    return (
        <Box px={0} pt={6} maxH='auto' overflowY='scroll' pb={110} overflowX='hidden' pos='relative'>
            <Flex>
                <Box w={{ md: '40%' }} display={{ base: 'none', lg: 'block' }} pr={1}>
                    <ScheduleClasses setSelectedDate={setSelectedDate} selectedDate={selectedDate} currentTeacherId={currentTeacherId} />
                    <Box className="extra-class-btn">
                        <Button onClick={() => setShowExtraClassModal(true)}>
                            Extra Class <PlusSquareIcon />
                        </Button>
                    </Box>
                </Box>
                <TodayClasses selectedDate={selectedDate} currentTeacherId={(id) => currTeacherId(id)} />
            </Flex>
            <Box>
                {
                    showExtraClassModal && (
                        <AddExtraClass
                            show={showExtraClassModal}
                            toggleModal={() => setShowExtraClassModal(false)}
                        />
                    )
                }
            </Box>
        </Box>

    )
}

export const ScheduleClasses = ({ setSelectedDate, selectedDate, currentTeacherId }) => {
    const dispatch = useDispatch();
    const [currentDate, setCurrentDate] = useState(moment());



    const { user, plannerScheduleDetails, plannerScheduleList } = useSelector((s) => ({
        pageConfig: s.doubts.pageConfig,
        closeDoubtStatus: s.doubts.closeDoubtStatus,
        teacherAssignedData: s.doubts.teacherAssignedData || [],
        user: s.user.user,
        plannerScheduleDetails: s.planner.plannerScheduleDetails,
        plannerScheduleList: s.planner.plannerScheduleList,
    }));


    useEffect(() => {
        if (user?._id) {
           
            dispatch(getPlannerScheduleAction({ staffIds: [user?.staff?._id], date: currentDate.format("YYYY-MM-DD") }));
            dispatch(getPlannerEventRequestListAction());
        }
    }, [user, currentTeacherId]);

    return (
        <>
            <Box>
                <Flex align={"stretch"}>
                    <Box flex={10}>
                        <Box>
                            <Box px={[1, 1, 1, 5]}>
                                <Box
                                    className="overflow-auto text-center events-heading-block"
                                    py={2}
                                    px={1}
                                   
                                    style={{ height: "max-content" }}
                                    borderRadius={5}
                                    boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                                >
                                    CALENDAR
                                </Box>
                                <Box
                                    className="overflow-auto "
                                    py={2}
                                    px={1}
                                    
                                    style={{ height: "max-content" }}
                                    borderRadius={5}
                                    boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px"
                                >
                                    <Box py={4} bg="white"
                                       
                                        borderColor="gray.200">
                                        <Box px={4}>
                                            <Heading size="sm">Upcoming Events</Heading>
                                            <Flex mt={6} align="center" justify="space-between">
                                                <Box>{moment(selectedDate).format("MMM YYYY")}</Box>
                                                <DateButton
                                                    user={user}
                                                    selectedDate={selectedDate}
                                                    setSelectedDate={setSelectedDate}
                                                />
                                            </Flex>
                                        </Box>
                                        <Box mt={2}>
                                            <ClassDates
                                                setSelectedDate={setSelectedDate}
                                                selectedDate={selectedDate}
                                                plannerScheduleDetails={plannerScheduleDetails}
                                                plannerScheduleList={plannerScheduleList}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Flex>

            </Box>

        </>
    );
};

const DateButton = ({ user, selectedDate, setSelectedDate }) => {
    const dispatch = useDispatch();

    const handleDateChange = (d) => {
      
        const newMonth = moment(d).format("MM");
        const newYear = moment(d).format("YYYY");
        const currentMonth = moment(selectedDate).format("MM");
        const currentYear = moment(selectedDate).format("YYYY");

        if (currentMonth !== newMonth || currentYear !== newYear) {
            dispatch(getPlannerScheduleAction({ staffIds: [user?.staff?._id], date: moment(d).format("YYYY-MM-DD") }));
       
        }
        setSelectedDate(d);
    }

    return (
        <Box>
            <Input
                borderColor="transparent"
                value={moment(selectedDate).format("YYYY-MM-DD")}
                onChange={(e) => handleDateChange(moment(e.target.value))}
                w={55}
                type="date"
            />
        </Box>
    );
};

const ClassDates = ({ selectedDate, setSelectedDate, plannerScheduleDetails, plannerScheduleList }) => {
    const [dates, setDates] = useState([]);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        if (selectedDate) {
            let newDates = [];
            const firstDateOfMonth = moment(selectedDate).set("date", 1);
            const endDateOfMonth = moment(firstDateOfMonth)
                .add(1, "month")
                .subtract(1, "day");
            let currentDate = firstDateOfMonth;

            let gapDates = firstDateOfMonth.get("day");

            while (gapDates > 0) {
                newDates.push(null);
                gapDates -= 1;
            }
            while (currentDate <= endDateOfMonth) {
                newDates.push(currentDate);
                currentDate = moment(currentDate).add(1, "days");
            }
            setDates(newDates);
        }
    }, [selectedDate]);

    useEffect(() => {
        if (plannerScheduleList && plannerScheduleList?.length > 0) {
            setEvents(plannerScheduleList);
        } else if (!plannerScheduleList) {
            setEvents([])
        }
    }, [plannerScheduleList]);

    const handleDateChange = (d) => {
        const newMonth = moment(d).format("MM");
        const newYear = moment(d).format("YYYY");
        const currentMonth = moment(selectedDate).format("MM");
        const currentYear = moment(selectedDate).format("YYYY");


        if (currentMonth !== newMonth || currentYear !== newYear) {
    
        }
        setSelectedDate(d);
    }
    return (
        <Box w="100%" px={1}>
            <Flex w="100%" wrap="wrap">
                {map(weekDays, (d, i) => (
                    <Box
                        fontSize="xs"
                        textAlign="center"
                        fontWeight="bold"
                        color="gray.500"
                        p={2}
                        minW={100 / weekDays.length + "%"}
                        maxW={100 / weekDays.length + "%"}
                        key={i}
                    >
                        {d}
                    </Box>
                ))}

                {map(dates, (d, i) => {
                    if (d) {
                    }
                    const isSelected = d &&
                        d.format("YYYY-MM-DD") === moment(selectedDate).format("YYYY-MM-DD");

                    const hasClasses = d && events?.find((e) => {
                        return moment(e.startDate).format("YYYY-MM-DD") === d.format("YYYY-MM-DD")
                    }
                    );

                    return (
                        <Box
                            cursor="pointer"
                            onClick={() => d && handleDateChange(d)}
                            pos="relative"
                            borderRadius={5}
                            bg={isSelected ? "blue.400" : "inherit"}
                            fontSize="sm"
                            textAlign="center"
                            fontWeight="bold"
                            color={isSelected ? "white" : "gray.800"}
                            p={2}
                            minW={100 / weekDays.length + "%"}
                            maxW={100 / weekDays.length + "%"}
                            key={i}
                        >
                            <Box key={i}>{d ? d.format("DD") : ""}</Box>
                            {hasClasses && d && (
                                <Box
                                    w={2}
                                    h={2}
                                    borderRadius={10}
                                    bg={isSelected ? "white" : "blue.400"}
                                    pos="absolute"
                                    left="50%"
                                    transform="translateX(-50%)"
                                    bottom={"2px"}
                                >
                                </Box>
                            )}
                        </Box>
                    );
                })}
            </Flex>
        </Box>
    );
};