import React from 'react';
import {ChakraProvider} from '@chakra-ui/react';
import AppRouter from '../routes';
import {AppProvider} from './AppProvider';
import {defaultTheme} from '../theme/default';
import {CartProvider} from '../Cart/CartProvider';
import '../routes/ProfileComponent/styles.css';
import '../customStyles.css';
import {URLSearchParams} from 'url';

if (typeof window === 'undefined') {
  global.URLSearchParams = URLSearchParams;
}

export const App = props => {
  return (
    <ChakraProvider theme={defaultTheme}>
      <AppProvider>
        <CartProvider>
          <AppRouter />
        </CartProvider>
      </AppProvider>
    </ChakraProvider>
  );
};
