import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { STATUS } from "../../../App/Constants";
import { apis } from "../../../services/api/apis";


export const updateUserAddressAction = createAsyncThunk(
	"address/post",
	async (payload, thunkAPI) => {
		const response = await apis.updateUserAddressApi(payload);
		const { ok, problem, data } = response;
		if (ok) {
			return data;
		} else {
			return thunkAPI.rejectWithValue(problem);
		}
	}
);

export const userAddressUpdateAction = createAsyncThunk(
	"address/patch",
	async (payload, thunkAPI) => {

		const response = await apis.userAddressUpdateApi(payload);
		const { ok, problem, data } = response;
		if (ok) {
			return data;
		} else {
			return thunkAPI.rejectWithValue(problem);
		}
	}
);

export const requestUserAddressAction = createAsyncThunk(
	"address/get",
	async (payload, thunkAPI) => {
		const response = await apis.requestUserAddressApi(payload);
		const { ok, problem, data } = response;
		if (ok) {
			return data;
		} else {
			return thunkAPI.rejectWithValue(problem);
		}
	}
);

const initialState = {};

const addressSlice = createSlice({
	name: "address",
	initialState,
	reducers: {
	},
	extraReducers: {
		[updateUserAddressAction.pending]: (state, action) => {
			state.addressUpdateStatus = STATUS.FETCHING;
		},

		[updateUserAddressAction.rejected]: (state, action) => {
			state.addressUpdateStatus = STATUS.FAILED;
		},

		[updateUserAddressAction.fulfilled]: (state, action) => {
			state.addressUpdateStatus = STATUS.SUCCESS;
			let newAddressData = action.payload;
			let existingAddresses = JSON.parse(JSON.stringify(state.userAddress));
			let newAddresses = [...existingAddresses, newAddressData]
			state.userAddress = newAddresses
		},

		//update a particular address

		[userAddressUpdateAction.pending]: (state, action) => {
			state.updateAddressStatus = STATUS.FETCHING;
		},

		[userAddressUpdateAction.rejected]: (state, action) => {
			state.updateAddressStatus = STATUS.FAILED;
		},

		[userAddressUpdateAction.fulfilled]: (state, action) => {
			state.updateAddressStatus = STATUS.SUCCESS;
			state.userAddress[current(state.userAddress).map((a) => { return a._id }).indexOf(action.payload._id)] = Object.assign({}, action.payload)
		},

		[requestUserAddressAction.pending]: (state, action) => {
			state.addressRequestStatus = STATUS.FETCHING;
		},

		[requestUserAddressAction.rejected]: (state, action) => {
			state.addressRequestStatus = STATUS.FAILED;
		},

		[requestUserAddressAction.fulfilled]: (state, action) => {
			state.addressRequestStatus = STATUS.SUCCESS;
			state.userAddress = action.payload;
		},
	},
});

export const addressReducer = addressSlice.reducer;
