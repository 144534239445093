import { Box } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ErrorChecker } from '../Components/ErrorChecker'
import { STATUS } from '../Constants'
import { getSingleDoubtAction } from '../redux/reducers/doubt'
import { DoubtComponent } from './doubt'

export const SingleDoubt = () => {
    const dispatch = useDispatch()
    const params = useParams()

    const {currentDoubt} = useSelector(state => ({
        currentDoubt:state.doubts.currentDoubt
    }))

    useEffect(() => {
        dispatch(getSingleDoubtAction({doubtId:params.id}))
    }, [dispatch, params])
    
    return(
            currentDoubt ? 
                <Box p={5}>
                    <DoubtComponent currentDoubt={currentDoubt} />
                </Box>
            : null
    )
}