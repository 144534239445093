
import React, { useReducer, useState, useEffect } from "react";

import _ from 'lodash'
import { useDispatch, useSelector } from "react-redux";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { Box, Button, Center, Checkbox, Flex, HStack, IconButton, Image, Spacer, Text, Textarea, useToast } from "@chakra-ui/react";

import { SectionHeader } from "../../Components/SectionHeader";
import { STATUS } from "../../App/Constants";
import { addMentorFeedbackAction, getFeedbackTagListAction } from "../../redux/reducers/mentorship";
import { useCookies } from "react-cookie";

export const Feedback = ({ setFeedbackModal}) => {
    const [starRating, setStarRating] = useState(5);
    let [message, setMessage] = React.useState("");
    const [tags, setTags] = React.useState([]);
    const [selectedTags, setSelectedTags] = React.useState([]);
    const [reportSession, setReportSession] = React.useState(false);
    const [cookies, setCookie, removeCookie] = useCookies();
    const dispatch = useDispatch();
    const toast = useToast();

    const { feedback, feedbackTagList, feedbackTagListStatus, mentorFeedbackStatus } = useSelector((s) => ({
        feedbackTagList: s.mentorship.feedbackTagList,
        mentorFeedbackStatus: s.mentorship.mentorFeedbackStatus,
        feedbackTagListStatus: s.mentorship.feedbackTagListStatus,
    }));

    useEffect(() => {
        dispatch(getFeedbackTagListAction());
    }, []);

    useEffect(() => {
        if (feedbackTagList?.length > 0) {
            const allTag = [];
            const starRatingTags = feedbackTagList?.filter((tag) => tag.star === starRating);
            starRatingTags?.map((tag) => allTag?.push(...tag.tags));
            setTags(allTag);
            setSelectedTags([]);
        }
    }, [feedbackTagList, starRating]);

    const handleStarRatingChange = (rating) => {
        setStarRating(rating+1)
    }

    let handleMessageChange = (e) => {
        setMessage(e.target.value)
    }
    const postFeedback = (e) => {
        e.preventDefault()

        if (message.trim() === '') {
            toast.closeAll();
            toast({
              
                description: "Please enter your feedback message.",
                status: "warning",
                duration: 3000,
                position: "top",
               
            })
            return;
        }
        const feedbackPayload = {
            _id:  cookies?.sessionId,
            rating: starRating,
            reportSession: reportSession,
            tags: selectedTags,
            feedback: message,
        }
       
        dispatch(addMentorFeedbackAction(feedbackPayload));
    }

    useEffect(() => {
        if (mentorFeedbackStatus === STATUS.SUCCESS) {
            toast({
                title: "Feedback Submitted.",
                description: "Thank you for your Feedback.",
                status: "success",
                duration: 3000,
                position: "top",
              
            })
        } else if (mentorFeedbackStatus === STATUS.FAILED) {
            toast({
                title: "Something Went Wrong",
                description: "Couldn't Process your request at the moment.",
                status: "error",
                duration: 3000,
                position: "top",
        
            })
        }
    }, [mentorFeedbackStatus]);

    const handleTagsChange = (tag, index) => {
        if (selectedTags?.includes(tag)) {
            setSelectedTags(prev => prev.filter(t => t !== tag));
        } else {
            setSelectedTags((prev) => [...prev, tag]);
        }
    };

    return (
        <Box>
            <Box boxShadow='md' borderRadius="xl" bg="white" p="1.5rem">
                <Box display="flex" >
                    <Box width="100%" display="flex" flexDirection="column">
                        <form onSubmit={postFeedback} width="50%">
                            <Box marginBottom="1rem"  >
                                <Text style={{ textAlign: "center" }} fontSize="large" fontWeight="bold" marginBottom="10px">Congratulation</Text>
                                <Center>
                                    <Image style={{ textAlign: "center" }} src='/images/star-rating.png' />
                                </Center>
                                <Text style={{ textAlign: "center" }} fontSize="sm" color="text.300" my={3}>You have successfully completed your session Please rate the session and provide your valuable feedback</Text>
                                <Text style={{ textAlign: "center" }} fontSize="" color="#797DFA" marginBottom="8px">Rate this session</Text>
                                <StarsRatingInput style={{ textAlign: "center" }} updateRating={handleStarRatingChange} rate={starRating-1} />
                                <Text style={{ textAlign: "center" }} fontSize="" color="#797DFA" marginBottom="10px">Share you experience</Text>
                                <Flex style={{ gap: "10px" }} justifyContent="center">
                                    {tags && tags.length > 0 &&
                                        tags.map((tag, index) =>
                                            <Text key={index}
                                                py={3}
                                                px={5}
                                                fontSize="sm"
                                                userSelect="none"
                                                cursor="pointer"
                                                borderRadius={15}
                                                color={_.includes(_.toUpper(selectedTags), _.toUpper(tag)) ? "white" : "#616165"}
                                                bg={selectedTags?.includes(tag) ? "#797DFA" : "#F7F7F7"}
                                                marginBottom="5px"
                                                onClick={() => handleTagsChange(tag, index)}
                                            >{tag}</Text>
                                        )
                                    }
                                </Flex>
                            </Box>
                            <Box mb={5}>
                                <Text fontSize="" color="#797DFA" marginBottom="10px" textAlign="center">Write a review</Text>
                                <Textarea
                                    value={message}
                                    onChange={handleMessageChange}
                                    placeholder="Write here"
                                    fontSize="sm"
                                    size="lg"
                                    height={'100px'}
                                />
                            </Box>
                            <Box mb={5}>
                                <Checkbox
                                    onChange={(e) => { setReportSession(e.target.checked) }}
                                    checked={reportSession}
                                >
                                    Report this session
                                </Checkbox>
                            </Box>
                            <Box display="flex" justifyContent="center" mt="2">
                                <Button type="submit" size="md" width="150px" color="#797DFA" borderRadius="15px"
                                >Submit</Button>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};


const StarsRatingInput = ({ updateRating, rate }) => {
    const [hoverRate, setHoverRate] = useState(rate);
    const updateHoverRate = (rating) => {
        setHoverRate(rating);
    }

    const StyleProps = {
        color: "#FECD52",
        fontSize: "25px",
        cursor: "pointer",
        minWidth: "unset",
        variant: "ghost",
        _hover: {
            color: '#FECD52',
        }
    }
    return (
        <Box mb={5}>
           
            <HStack justifyContent="center">
                {
                    [...Array(5).keys()].map((v) => {
                        return (
                       
                            <Box
                                {...StyleProps}
                                key={v}
                                onClick={() => updateRating(v)}
                                onMouseEnter={() => updateHoverRate(v)}
                                onMouseLeave={() => updateHoverRate(rate)}
                            >
                                {hoverRate > v - 1 ? <AiFillStar /> : <AiOutlineStar />}
                            </Box>

                        )
                    })
                }
              
            </HStack>
        </Box>
    )
}