import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { STATUS } from "../../../App/Constants";
import { apis } from "../../../services/api/apis";
import _ from "lodash";

export const getUserScheduleAction = createAsyncThunk(
    "planner/teacher",
    async (payload, thunkAPI) => {
        const response = await apis.getPlannerScheduleApi(payload);
        const { ok, problem, data } = response;

        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);
export const getPlannerScheduleAction = createAsyncThunk(
    "planner/schedules",
    async (payload, thunkAPI) => {
        const response = await apis.getPlannerSchedulesApi(payload);
        const { ok, problem, data } = response;
        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);

export const getOtherUserScheduleAction = createAsyncThunk(
    "planner/teacher/getOther",
    async (payload, thunkAPI) => {
        const response = await apis.getPlannerScheduleApi(payload);
        const { ok, problem, data } = response;

        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);

export const getPlannerListAction = createAsyncThunk(
    '/planner-list',
    async (payload, thunkAPI) => {
        const response = await apis.getPlannerListApi(payload)
        const { ok, problem, data } = response
        if (ok) {
            return data
        } else {
            return thunkAPI.rejectWithValue(problem)
        }
    }
)

export const getStaffsListAction = createAsyncThunk(
    "Staffs/list",
    async (payload, thunkAPI) => {
        const response = await apis.getStaffsListApi(payload);
        const { ok, problem, data } = response;

        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);

export const getPlannerEventRequestListAction = createAsyncThunk(
    "planner-event/request/list",
    async (payload, thunkAPI) => {
        const response = await apis.getPlannerEventRequestListApi(payload);
        const { ok, problem, data } = response;

        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);

export const deletePlannerEventRequestAction = createAsyncThunk(
    "delete/planner-event-request/list",
    async (payload, thunkAPI) => {
        const response = await apis.deletePlannerEventRequestApi(payload);
        const { ok, problem, data } = response;

        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);

export const updatePlannerEventRescheduleAction = createAsyncThunk(
    "update/planner-event-request/list",
    async (payload, thunkAPI) => {
        const response = await apis.updatePlannerEventRescheduleApi(payload);
        const { ok, problem, data } = response;

        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);

export const updatePlannerEventAction = createAsyncThunk(
    "update/planner-event/list",
    async (payload, thunkAPI) => {
        const response = await apis.updatePlannerEventApi(payload);
        const { ok, problem, data } = response;

        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);

export const createPlannerExtraClassAction = createAsyncThunk(
    "create/planner-extra-class/list",
    async (payload, thunkAPI) => {
        const response = await apis.createPlannerExtraClassRequestApi(payload);
        const { ok, problem, data } = response;
        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);

let initialState = {
    planerSchedule: [],
    staffList: []
};

const plannerSlice = createSlice({
    name: "planner",
    initialState,
    reducers: {
        resetPlannerExtraClassStatus: (state) => {
            state.plannerExtraClassStatus = STATUS.NOT_STARTED;
        },
    },
    extraReducers: {
        [getUserScheduleAction.pending]: (state) => {
            state.getPlannerScheduleStatus = STATUS.FETCHING;
        },

        [getUserScheduleAction.fulfilled]: (state, action) => {
            const response = action.payload.response;
            state.plannerScheduleDetails = response?.length > 0 ? response[0] : null;
            state.getPlannerScheduleStatus = STATUS.SUCCESS;
        },

        [getUserScheduleAction.rejected]: (state) => {
            state.getPlannerScheduleStatus = STATUS.FAILED;
        },
        [getPlannerScheduleAction.pending]: (state) => {
            state.getPlannerScheduleListStatus = STATUS.FETCHING;
        },

        [getPlannerScheduleAction.fulfilled]: (state, action) => {
            const response = action.payload.response;
            state.plannerScheduleList = response?.length > 0 ? response : [];
            state.getPlannerScheduleListStatus = STATUS.SUCCESS;
        },

        [getPlannerScheduleAction.rejected]: (state) => {
            state.getPlannerScheduleListStatus = STATUS.FAILED;
        },
        [getOtherUserScheduleAction.pending]: (state) => {
            state.getOtherPlannerScheduleStatus = STATUS.FETCHING;
        },

        [getOtherUserScheduleAction.fulfilled]: (state, action) => {
            const response = action.payload.response;
            state.otherPlannerScheduleDetails = response?.length > 0 ? response[0] : null;
            state.getOtherPlannerScheduleStatus = STATUS.SUCCESS;
        },

        [getOtherUserScheduleAction.rejected]: (state) => {
            state.getOtherPlannerScheduleStatus = STATUS.FAILED;
        },
        [getPlannerListAction.pending]: (state) => {
            state.plannerListStatus = STATUS.FETCHING
        },
        [getPlannerListAction.fulfilled]: (state, action) => {
            state.plannerList = action.payload.response;
            state.plannerListStatus = STATUS.SUCCESS;
        },
        [getPlannerListAction.rejected]: (state, action) => {
            state.plannerListStatus = STATUS.FAILED
        },

        [getStaffsListAction.pending]: (state) => {
            state.getStaffsListStatus = STATUS.FETCHING;
        },

        [getStaffsListAction.fulfilled]: (state, action) => {
            state.getStaffsListStatus = STATUS.SUCCESS;
            const staffList = action.payload[0].staffs;
            state.activeStaffsList = staffList?.filter(staff => staff?.user?.isActive === true && (staff?.staffRole === "TEACHER" || staff?.otherRoles?.includes("TEACHER")));
        },

        [getStaffsListAction.rejected]: (state) => {
            state.getStaffsListStatus = STATUS.FAILED;
        },
        [getPlannerEventRequestListAction.pending]: (state) => {
            state.getPlannerEventRequestListStatus = STATUS.FETCHING;
        },

        [getPlannerEventRequestListAction.fulfilled]: (state, action) => {
            state.getPlannerEventRequestListStatus = STATUS.SUCCESS;
            state.plannerRequestList = action.payload?.response;
        },

        [getPlannerEventRequestListAction.rejected]: (state) => {
            state.getPlannerEventRequestListStatus = STATUS.FAILED;
        },
        [deletePlannerEventRequestAction.pending]: (state) => {
            state.deletePlannerEventRequestListStatus = STATUS.FETCHING;
        },

        [deletePlannerEventRequestAction.fulfilled]: (state, action) => {
            state.deletePlannerEventRequestListStatus = STATUS.SUCCESS;
            state.deletePlannerRequestList = action.payload
        },

        [deletePlannerEventRequestAction.rejected]: (state) => {
            state.deletePlannerEventRequestListStatus = STATUS.FAILED;
        },
        [updatePlannerEventRescheduleAction.pending]: (state) => {
            state.updatePlannerEventRescheduleStatus = STATUS.FETCHING;
        },

        [updatePlannerEventRescheduleAction.fulfilled]: (state, action) => {
            state.updatePlannerEventRescheduleStatus = STATUS.SUCCESS;
            state.plannerEventRescheduleUpdateData = action.payload
        },

        [updatePlannerEventRescheduleAction.rejected]: (state) => {
            state.updatePlannerEventRescheduleStatus = STATUS.FAILED;
        },
        [updatePlannerEventAction.pending]: (state) => {
            state.updatePlannerEventStatus = STATUS.FETCHING;
        },

        [updatePlannerEventAction.fulfilled]: (state, action) => {
            state.updatePlannerEventStatus = STATUS.SUCCESS;
            state.plannerEventUpdateData = action.payload.response;
        },

        [updatePlannerEventAction.rejected]: (state) => {
            state.updatePlannerEventStatus = STATUS.FAILED;
        },
        [createPlannerExtraClassAction.pending]: (state) => {
            state.plannerExtraClassStatus = STATUS.FETCHING;
        },

        [createPlannerExtraClassAction.fulfilled]: (state, action) => {
            state.plannerExtraClassStatus = STATUS.SUCCESS;
            state.plannerExtraClassData = action.payload.response;
        },

        [createPlannerExtraClassAction.rejected]: (state) => {
            state.plannerExtraClassStatus = STATUS.FAILED;
        },
    },
});

export const { resetPlannerExtraClassStatus } = plannerSlice.actions
export const plannerReducer = plannerSlice.reducer;
