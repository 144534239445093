import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { STATUS } from "../../../App/Constants"
import { apis } from "../../../services/api/apis"
import { map } from 'lodash'



export const createTicketAction = createAsyncThunk(
	"ticket/post",
	async (payload, thunkAPI) => {
		const response = await apis.createTicketApi(payload)
		const { ok, problem, data } = response
		if (ok) {
			return data
		} else {
			return thunkAPI.rejectWithValue(problem)
		}
	}
)

export const requestUserTicketAction = createAsyncThunk(
	"ticket/get",
	async (payload, thunkAPI) => {
		const response = await apis.getUserTicketsApi(payload)
		const { ok, problem, data } = response
		if (ok) {
			return data
		} else {
			return thunkAPI.rejectWithValue(problem)
		}
	}
)

export const getSingleTicketAction = createAsyncThunk(
	"single-ticket/get",
	async (payload, thunkAPI) => {
		const response = await apis.getSingleTicketApi(payload)
		const { ok, problem, data } = response
		if (ok) {
			return data
		} else {
			return thunkAPI.rejectWithValue(problem)
		}
	}
)

export const getAllTicketAction = createAsyncThunk(
	"all-ticket/get",
	async (payload, thunkAPI) => {
		const response = await apis.getAllTicketApi(payload)
		const { ok, problem, data } = response
		if (ok) {
			return data
		} else {
			return thunkAPI.rejectWithValue(problem)
		}
	}
)

export const updateTicketAction = createAsyncThunk(
	"ticket/patch",
	async (payload, thunkAPI) => {
		const response = await apis.upadateTicketApi(payload)
		const { ok, problem, data } = response
		if (ok) {
			return data
		} else {
			return thunkAPI.rejectWithValue(problem)
		}
	}
)

export const getCurrentTicketUser = createAsyncThunk(
	"ticket/user",
	async (payload, thunkAPI) => {
		const response = await apis.studentDetailsApi(payload)
		const { ok, problem, data } = response
		if (ok) {
			return data
		} else {
			return thunkAPI.rejectWithValue(problem)
		}
	}
)



const initialState = {
	allTicket: {}
}

const ticketSlice = createSlice({
	name: "ticket",
	initialState,
	reducers: {
	},
	extraReducers: {
		[createTicketAction.pending]: (state, action) => {
			state.getTicketStatus = STATUS.FETCHING
		},

		[createTicketAction.rejected]: (state, action) => {
			state.getTicketStatus = STATUS.FAILED
		},

		[createTicketAction.fulfilled]: (state, action) => {
			state.getTicketStatus = STATUS.SUCCESS
			state.ticket = action.payload
		},



		[requestUserTicketAction.pending]: (state, action) => {
			state.getTicketRequestStatus = STATUS.FETCHING
		},

		[requestUserTicketAction.rejected]: (state, action) => {
			state.getTicketRequestStatus = STATUS.FAILED
		},

		[requestUserTicketAction.fulfilled]: (state, action) => {
			state.getTicketRequestStatus = STATUS.SUCCESS
			state.userTickets = action.payload
		},

		[getSingleTicketAction.pending]: (state, action) => {
			state.getTicketStatus = STATUS.FETCHING
		},

		[getSingleTicketAction.rejected]: (state, action) => {
			state.getTicketStatus = STATUS.FAILED
		},

		[getSingleTicketAction.fulfilled]: (state, action) => {
			state.getTicketStatus = STATUS.SUCCESS
			state.currentTicket = action.payload
		},


		[getAllTicketAction.pending]: (state, action) => {
			state.getTicketStatus = STATUS.FETCHING
		},

		[getAllTicketAction.rejected]: (state, action) => {
			state.getTicketStatus = STATUS.FAILED
		},

		[getAllTicketAction.fulfilled]: (state, action) => {
			state.getTicketStatus = STATUS.SUCCESS
			state.allTicket = action.payload
		},

		[updateTicketAction.pending]: (state, action) => {
			state.updateTicketStatus = STATUS.FETCHING
		},

		[updateTicketAction.rejected]: (state, action) => {
			state.updateTicketStatus = STATUS.FAILED
		},

		[updateTicketAction.fulfilled]: (state, action) => {
			state.updateTicketStatus = STATUS.SUCCESS
			state.allTicket.docs = map(state.allTicket.docs, t => t._id === action.payload._id ? action.payload : t)
		},


		[getCurrentTicketUser.pending]: (state, action) => {
			state.getStudentStatus = STATUS.FETCHING;
			state.currentStudent = null;
		},

		[getCurrentTicketUser.rejected]: (state, action) => {
			state.getStudentStatus = STATUS.FAILED
		},

		[getCurrentTicketUser.fulfilled]: (state, action) => {
			state.getStudentStatus = STATUS.SUCCESS
			state.currentStudent = action.payload;
		},
	},
})

export const ticketReducer = ticketSlice.reducer