import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Textarea,
  Tooltip,
  ModalFooter,
  Tag,
  FormErrorMessage,
} from "@chakra-ui/react";
import { forEach, map, reduce, size } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { URIS } from "../../services/api";
import { useApiRequest } from "../../services/api/useApiRequest";
import moment from "moment";
import { AttachmentIcon, SmallAddIcon, SmallCloseIcon } from "@chakra-ui/icons";
import Pagination from "@choc-ui/paginator";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { GrAttachment } from "react-icons/gr";
import { isImage, isPDF } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { blockUserCommentAction, removeVideoCommentAction, getCommentsAction, addCommentAction } from "../../redux/reducers/courses";
export const DiscussionComments = ({
  itemId,
  commentsLabel,
  sortType,
  type = "discussion",
  itemModel = "Topic",
  selectedAnswer,
  showAttachment = true,
  inputStyle = "filled",
  placeholder = "Add a public comment...",
  disableReply,
  disableComment,
  onBottomAtSmall,
  editCommentUserId,
  includeDoubt
}) => {
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState();
  const [activeReplyComment, setActiveReplyComment] = useState();
  const [pagedata, setPageData] = useState();
  const [curEditType, setCurEditType] = useState('add');
  const [loadSpinner, setLoadSpinner] = useState(false);


  const { commentsList, getCommentsStatus, addCommentStatus, getBlockUserStatus } = useSelector((state) => ({
    commentsList: state.course.commentsList,
    getCommentsStatus: state.course.getCommentsStatus,
    addCommentStatus: state.course.addCommentStatus,
    getBlockUserStatus: state.course.getBlockUserStatus,
  }))
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCommentsAction({ itemId: itemId, limit: 10, page: 1 }));
  }, [itemId])

  useEffect(() => {
    if (getCommentsStatus === "SUCCESS") {
      if (commentsList.page === 1) {
        setComments([...commentsList.docs]);
      } else {
        setComments([...comments, ...commentsList.docs])
      }
      if (commentsList.limit === 10) {
        setPageData({ pages: commentsList.pages, page: commentsList.page });
      }
      setActiveReplyComment(false)
    }
  }, [getCommentsStatus])

  useEffect(() => {
    if (addCommentStatus === "SUCCESS") {
      dispatch(getCommentsAction({ itemId: itemId, limit: pagedata?.page * 10 + 1, page: 1 }));
    }
  }, [addCommentStatus])



  const mappedComments = useMemo(() => {
    return reduce(
      comments,
      (last, c, i) => {
        if (c.replies && c.childComments && size(c.childComments)) {
          last.push(
            Object.assign({}, c, {
              sizecc: size(c.childComments),
            })
          );

          forEach(c.childComments, (cc) => {
            last.push(
              Object.assign({}, cc, {
                type: "child",
              })
            );
          });
        } else {
          last.push(c);
        }

        return last;
      },
      []
    );
  }, [comments]);


  const addComment = (data, attachment) => {
    const formData = new FormData();

    let trimmedDATA = data.trim();

    formData.append("comment", trimmedDATA);
    if (attachment) {
      formData.append("upload", attachment);
    }

    if (includeDoubt) {
      formData.append("includeDoubt", includeDoubt);
    }

    if (curEditType === 'edit') {
      formData.append("commentId", activeReplyComment._id);
    }
    else {
      formData.append("itemModel", itemModel);
      formData.append("itemId", itemId);
      if (activeReplyComment) {
        formData.append("parentComment", activeReplyComment._id);
      }
    }

    if (trimmedDATA && trimmedDATA !== '') {
      dispatch(addCommentAction(formData));
    }

  };

  const [lightboxUri, setLightBoxUri] = useState();

  const closeLightBox = () => {
    setLightBoxUri();
  };

  const loadMore = () => {
    dispatch(getCommentsAction({ itemId: itemId, limit: 10, page: (pagedata?.page || 0) + 1 }));
    setLoadSpinner(true)
  }

  useEffect(() => {
    if (getCommentsStatus === "SUCCESS") {
      setLoadSpinner(false);
    }
  }, [getCommentsStatus])

  return (
    <Box pos="relative" pb={onBottomAtSmall ? ['7rem', '7rem', '7rem', '0rem'] : null}>
      <Box pb={4}>
        {
          commentsLabel ?
            <Text fontSize="sm">{commentsLabel} ({comments?.length || 0})</Text>
            :
            <Text fontSize="sm">{comments?.length || 0} COMMENTS</Text>
        }

      </Box>
      {
        !disableComment &&
        <Box bottom="0px" w="95%" pos={onBottomAtSmall ? ['fixed', 'fixed', 'fixed', 'relative'] : null} bg='white' zIndex={99}>
          <CommentBox
            addComment={addComment}
            setComment={setComment}
            comment={!activeReplyComment ? comment : ''}
            inputStyle={inputStyle}
            showAttachment={showAttachment}

            placeholder={placeholder}
          />
        </Box>
      }
      {mappedComments.map((comment, i) => {
        return (
          <CommentItem
            disableReply={disableReply}
            canUserEdit={editCommentUserId && editCommentUserId === comment.user._id}
            setLightBoxUri={setLightBoxUri}
            key={comment._id + i}
            setComments={setComments}
            item={comment}
            index={i}
            setActiveReplyComment={(item, type) => { setCurEditType(type); if (type === 'edit') setComment(item.comment); setActiveReplyComment(item); }}
            mappedComments={mappedComments}

            itemId={itemId}
            pagedata={pagedata}
            getBlockUserStatus={getBlockUserStatus}
          />
        );
      })}
      {activeReplyComment ? (
        <AddCommentModal
          addComment={addComment}
          setComment={setComment}
          comment={comment}
          editCommentUserId={curEditType === 'edit' ? true : false}
          inputStyle={inputStyle}
          showAttachment={showAttachment}

          activeComment={activeReplyComment}
          closeModal={() => { setActiveReplyComment(false); setComment('') }}
          placeholder={placeholder}
        />
      ) : null}

      <Box p={2}>
        {loadSpinner ? (
          <Spinner ml={10} size="lg" />
        ) : pagedata && pagedata.page && pagedata.pages > pagedata.page ? (
          <Button variant="gray" onClick={loadMore}>
            Load More
          </Button>
        ) : comments && comments.length ? (
          <Text>No more comments. </Text>
        ) : null}
      </Box>

      <ImageViewLightBox uri={lightboxUri} onClose={closeLightBox} />
    </Box>
  );
};

export const CommentItem = ({
  item,
  setLightBoxUri,
  setComments,
  index,
  setActiveReplyComment,
  disableReply,
  canUserEdit,

  itemId,
  pagedata,
  getBlockUserStatus,
}) => {
  const { status, user, commentsBlockList } = useSelector(s => ({
    status: s.user.status,
    user: s.user.user,

    commentsBlockList: s.course.commentsBlockList,
  }));
  const [comm, setComm] = useState({ ...item })
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState(7);
  const [error, setError] = useState(false);
  const _setActiveReplyComment = (type) => {
    setActiveReplyComment(item, type);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    setComm({ ...item });
  }, [item])

  const onCompletedLike = useCallback(
    (data) => {
      setComments((c) =>
        map(c, (ci) => {
          const pid =
            typeof data.parentComment === "object"
              ? data.parentComment._id
              : data.parentComment;
          if (data.parentComment && ci._id === pid) {
            const childComments = map(ci.childComments, (cci) => {
              if (cci._id === data._id) {
                return data;
              }
              return cci;
            });

            return Object.assign({}, ci, {
              childComments,
            });
          }

          if (ci._id === data._id) {
            return Object.assign({}, data, {
              childComments: ci.childComments,
            });
          }
          return ci;
        })
      );
    },
    [setComments]
  );

  const onErrorLike = useCallback((res) => { }, []);
  const { request: likeComment, loadingComment } = useApiRequest(
    URIS.ADD_LIKE,
    {
      onCompleted: onCompletedLike,
      onError: onErrorLike,
    }
  );
  const _like = () => {
    likeComment({
      method: "PATCH",
      data: {
        path: item.self && item.self === "dislikes" ? "dislikes" : "likes",
        commentId: item._id,
      },
    });
  };

  const onCompletedReplies = useCallback(
    (data, resp, extraData) => {
      setComments((c) =>
        map(c, (cr) => {
          if (cr._id === extraData.commentId) {
            return Object.assign({}, cr, {
              childComments: data.docs,
            });
          }
          return cr;
        })
      );
    },
    [setComments]
  );

  const onErrorReplies = useCallback((res) => { }, []);

  const { request: commentRepliesRequest, loadingReplies } = useApiRequest(
    URIS.GET_COMMENT_REPLIES,
    {
      onCompleted: onCompletedReplies,
      onError: onErrorReplies,
    }
  );

  const _viewReplies = () => {
    commentRepliesRequest(
      {
        method: "GET",
        params: {
          commentId: item._id,
        },
      },
      {
        commentId: item._id,
      }
    );
  };
  const handleMarkAsDelete = (id) => {
    dispatch(removeVideoCommentAction({ commentId: id }))
    setComm({ ...comm, isDeleted: true });
  }

  const handleBlockAction = () => {
    setIsModalVisible(true);
  }
  const handleBlockUser = (e, id) => {
    e.preventDefault();
    if ((inputValue.toString()).includes('.') || parseInt(inputValue) === 0) {
      setError(true);
    } else {
      setError(false);
      dispatch(blockUserCommentAction({ userId: id, noOfDays: inputValue }));

    }
  }
  useEffect(() => {
    if (getBlockUserStatus === "SUCCESS" && isModalVisible) {
      dispatch(getCommentsAction({ itemId: itemId, limit: pagedata?.page * 10 + 1, page: 1 }));
      setIsModalVisible(false);
    }
  }, [getBlockUserStatus])

  return (
    <Flex
      py={comm.parentComment ? 4 : 5}
      pl={comm.parentComment ? 10 : 1}
      mb="5px"
      bg={comm?.isDeleted || comm?.isCommentblock ? "rgb(236,236,236)" : comm.parentComment ? "gray.50" : "white"}
      borderRadius="15px"
    >
      <Avatar name={comm.user?.name} size="sm" color="white" />
      <Box flex={1} ml={4} >
        <HStack spacing={2}>
          <Heading fontSize="sm">{comm.user?.name}</Heading>
          <Text fontSize="xs" color="gray.500">
            {moment(comm.createdAt).format("DD MMM, HH:mm")}
          </Text>
          {comm.isCommentblock ? <Tag style={{ color: "white", backgroundColor: "#e34e33" }}>Blocked</Tag> : null}
        </HStack>
        <Text whiteSpace='pre-wrap'>{comm?.isDeleted ? <del>{comm.comment == "undefined" ? "" : comm.comment}</del>
          : comm.comment == "undefined" ? "" : comm.comment}
        </Text>
        {comm.files && comm.files.length && !comm?.isCommentblock ? (
          <Flex>
            {comm.files.map((f) => {
              return (
                <FileView key={f._id} f={f} setLightBoxUri={setLightBoxUri} />
              );
            })}
          </Flex>
        ) : null}
        <Flex mt={3} fontSize={"sm"}>
          {comm?.isDeleted || comm.isCommentblock ? null : loadingComment ? (
            <Spinner size="sm" />
          ) : (
            <Text
              color="gray.400"
              cursor="pointer"
              onClick={_like}
              _hover={{ color: "gray.500" }}
            >
              {comm.totalLikes > 0 ? "Liked" : "Like"}{" "}
            </Text>
          )}
          {comm.totalLikes ? (
            <Text ml={4} color="gray.400">
              Likes {comm.totalLikes}
            </Text>
          ) : null}
          {comm.parentComment || disableReply || comm?.isDeleted || comm.isCommentblock ? null : (
            <Text
              ml={4}
              color="gray.400"
              cursor="pointer"
              _hover={{ color: "gray.500" }}
              onClick={() => _setActiveReplyComment('reply')}
            >
              Reply
            </Text>
          )}
          {(comm.isDeleted || comm.isDeleted == undefined) ?
            null : (
              <Text
                ml={4}
                color="gray.400"
                cursor="pointer"
                _hover={{ color: "gray.500" }}
                onClick={() => handleMarkAsDelete(comm?._id)}
              >
                Delete
              </Text>
            )}
          {!comm.isCommentblock && comm?.user?._id !== user?._id ? (
            <Text
              ml={4}
              color="gray.400"
              cursor="pointer"
              _hover={{ color: "gray.500" }}
              onClick={() => handleBlockAction()}
            >
              Block
            </Text>
          ) : null
          }
          <Modal
            isOpen={isModalVisible ? true : false}
            onClose={() => setIsModalVisible(false)}
            size="4xl"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{"Number of days"}</ModalHeader>
              <ModalCloseButton />
              <form onSubmit={(e) => handleBlockUser(e, comm?.user?._id)}>
                <ModalBody>
                  <>
                    <FormControl mb={4} id="topic" isRequired>
                      <FormLabel>No. of days</FormLabel>
                      <Input
                        placeholder="Enter days"
                        type="number"
                        value={inputValue}
                        onChange={(e) => { setInputValue(e.target.value) }}
                      />
                      {error && (
                        <span style={{ color: 'red', fontSize: '14px' }}>
                          Decimal values and 0 are not allowed.
                        </span>
                      )}
                    </FormControl>
                  </>
                </ModalBody>
                <ModalFooter>
                  <Button type='submit'

                  >Submit</Button>
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
          {
            canUserEdit &&
            <Text
              ml={4}
              color="gray.400"
              cursor="pointer"
              _hover={{ color: "gray.500" }}
              onClick={() => _setActiveReplyComment('edit')}
            >
              Edit
            </Text>
          }

          {!comm?.isDeleted && comm.replies && comm.replies !== size(comm.childComments) ? (
            <Button size="xs" variant="text" ml={4} onClick={_viewReplies}>
              <Text as="u" color="gray.500">
                View {comm.replies - size(comm.childComments)} Replies
              </Text>
            </Button>
          ) : null}
        </Flex>
      </Box>
    </Flex>
  );
};

const FileView = ({ f, setLightBoxUri }) => {
  const isFileImage = isImage(f.url, f.type);
  const isFilePdf = isPDF(f.url, f.type);

  return isFileImage ? (
    <Box
      cursor={"pointer"}
      onClick={() => setLightBoxUri(f.url)}
      _hover={{ bg: "gray.200" }}
    >
      <Image src={f.url} minHeight={"200px"} maxHeight={"400px"} />
    </Box>
  ) : (
    <Box
      cursor={"pointer"}
      onClick={() => window.open(f.url, "_")}
      _hover={{ bg: "gray.200" }}
    >
      <AttachmentIcon m={5} w={"30px"} h="30px" />
    </Box>
  );
};

const CommentBox = ({
  addComment,
  inputStyle,
  showAttachment,
  comment,
  loading,
  setComment,
  placeholder,
}) => {
  const [attachment, changeAttachment] = useState({ file: "", fileType: "" });

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    _addAttachment(event);
  };

  const _addAttachment = (e) => {
    let file = e.target.files[0];
    let fileType;

    if (!file) {
      return;
    }

    if (file.type.split("/")[0] == "image") {
      fileType = "images";
    } else if (file.type.split("/")[0] == "video") {
      fileType = "videos";
    } else if (file.type.split("/")[0] == "audio") {
      fileType = "audios";
    } else {
      fileType = "documents";
    }

    changeAttachment({ file: e.target.files[0], fileType: fileType });
  };

  const _changeComment = (e) => {
    setComment(e.target.value);
  };
  const clearComment = () => {
    setComment("");
    changeAttachment({ file: "", fileType: "" });
  };

  const submitComment = () => {
    let att = attachment?.file;
    changeAttachment({ file: "", fileType: "" });
    addComment(comment, att);
    setComment("");
  };

  return (
    <Box>
      {showAttachment && attachment.file ? (
        <Flex alignItems="center" mb={2} p={2} bg="gray.50">
          <IconButton
            size="sm"
            onClick={() => {
              changeAttachment({ file: "", fileType: "" });
            }}
            icon={<SmallCloseIcon />}
          />
          <Text ml={3}>{attachment.file.name}</Text>
          <Text ml={3} fontSize="xs" color="gray.400">
            {(((attachment.file.size / 1024 / 1024) * 100) / 100).toFixed(2)} MB
          </Text>


        </Flex>
      ) : null}
      <Flex style={{ alignItems: "flex-start" }}>
        {showAttachment ? (
          <>
            <Tooltip label="attach file">
              <Box
                onClick={handleClick}
                _hover={{ color: "primary.400", fontSize: "xl" }}
                style={{
                  cursor: "pointer",
                  border: "1px solid #707070A7",
                  borderStyle: "dashed",
                  padding: "10px",
                }}
              >
                <HStack
                  justifyContent="center"
                  align="center"
                  style={{ textAlign: "center" }}
                  boxSize={8}
                >
                  <span>
                    <GrAttachment fontSize="18px" />
                  </span>
                </HStack>
                <input
                  {...{ accept: "*/*" }}
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
              </Box>
            </Tooltip>
          </>
        ) : null}
        <Textarea
          ml={3}
          variant={inputStyle}
          size='md'
          alignSelf="stretch"
          value={comment}
          onChange={_changeComment}
          placeholder={placeholder}
        />
      </Flex>
      <HStack spacing={4} my={2} justifyContent="flex-end" width="100%">
        <Button
          border="1px solid #4285F4"
          borderRadius={0}
          variant="outline"
          size="sm"
          onClick={clearComment}
          colorScheme="blue"
        >
          CLEAR
        </Button>
        <Button
          border="1px solid #4285F4"
          borderRadius={0}
          variant="solid"
          size="sm"
          isLoading={loading}
          disabled={!comment && !attachment?.file}
          onClick={submitComment}
          colorScheme="blue"
        >
          COMMENT
        </Button>
      </HStack>
    </Box>
  );
};

const AddCommentModal = ({ activeComment, closeModal, editCommentUserId, ...rest }) => {
  return (
    <Modal
      isOpen={activeComment ? true : false}
      onClose={closeModal}
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{editCommentUserId ? "Edit comment" : "Add Reply"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CommentBox {...rest} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const ImageViewLightBox = ({ uri, onClose }) => {
  const [zoom, setZoom] = useState(1);

  const zoomIn = () => {
    setZoom((z) => parseFloat(z + 0.1));
  };
  const zoomOut = () => {
    setZoom((z) => parseFloat(z - 0.1));
  };

  return (
    <Modal
      size="full"
      closeOnOverlayClick={false}
      isOpen={uri ? true : false}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        style={{
          width: "100%",
          position: "relative",
          margin: 0,
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          backgroundColor: "transparent",
        }}
      >
        <ModalCloseButton />

        <Image

          maxHeight="100vh"
          maxWeight="100vh"
          src={uri}
        />

      </ModalContent>
    </Modal>
  );
}
