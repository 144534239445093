import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { STATUS } from "../../../App/Constants";
import { apis } from "../../../services/api/apis";
import _ from "lodash";


export const getMentorEventsListAction = createAsyncThunk(
    "mentorship/events",
    async (payload, thunkAPI) => {
        const response = await apis.getMentorshipEventsApi(payload);
        const { ok, problem, data } = response;
        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);
export const getFeedbackTagListAction = createAsyncThunk(
    "feedback/tag-list/mentorship/events",
    async (payload, thunkAPI) => {
        const response = await apis.feedbackTagListApi(payload);
        const { ok, problem, data } = response;
        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);
export const startMentorshipSessionAction = createAsyncThunk(
    "start/mentorship/events",
    async (payload, thunkAPI) => {
        const response = await apis.startMentorshipSessionApi(payload);
        const { ok, problem, data } = response;
        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);
export const endMentorshipSessionAction = createAsyncThunk(
    "end/mentorship/events",
    async (payload, thunkAPI) => {
        const response = await apis.endMentorshipSessionApi(payload);
        const { ok, problem, data } = response;
        if (ok) {
            return { data, extraData: payload };
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);
export const addMentorFeedbackAction = createAsyncThunk(
    "feedback/mentorship/events",
    async (payload, thunkAPI) => {
        const response = await apis.addMentorFeedbackAPi(payload);
        const { ok, problem, data } = response;
        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);

let initialState = {
    mentorEvents: [],
    staffList: [],
};

const mentorshipSlice = createSlice({
    name: "mentorship",
    initialState,
    reducers: {
        resetEndMentorshipSessionStatus: (state) => {
            state.endMentorshipSessionStatus = STATUS.NOT_STARTED;
        },
        resetStartMentorshipSessionStatus: (state) => {
            state.startMentorshipSessionStatus = STATUS.NOT_STARTED;
        },
        resetMentorFeedbackStatus: (state) => {
            state.mentorFeedbackStatus = STATUS.NOT_STARTED;
        },
        setMentorshipCurrentSessionData: (state) => {
            state.mentorshipCurrentSessionData = {};
        }
    },
    extraReducers: {

        [getMentorEventsListAction.pending]: (state) => {
            state.getMentorEventsStatus = STATUS.FETCHING;
        },
        [getMentorEventsListAction.fulfilled]: (state, action) => {
            state.getMentorEventsStatus = STATUS.SUCCESS;
            const res = action.payload.response;
            state.mentorEventsList = res?.length > 0 ? res : [];
        },
        [getMentorEventsListAction.rejected]: (state) => {
            state.getMentorEventsStatus = STATUS.FAILED;
        },


        [getFeedbackTagListAction.pending]: (state) => {
            state.feedbackTagListStatus = STATUS.FETCHING;
        },
        [getFeedbackTagListAction.fulfilled]: (state, action) => {
            state.feedbackTagListStatus = STATUS.SUCCESS;
            const res = action.payload.response;
            const mentorTagList = res?.length > 0 && res?.filter((tag) => tag?.type === "mentor");
            state.feedbackTagList = mentorTagList?.length > 0 ? mentorTagList : [];
        },
        [getFeedbackTagListAction.rejected]: (state) => {
            state.feedbackTagListStatus = STATUS.FAILED;
        },


        [startMentorshipSessionAction.pending]: (state) => {
            state.startMentorshipSessionStatus = STATUS.FETCHING;
        },
        [startMentorshipSessionAction.fulfilled]: (state, action) => {
            state.startMentorshipSessionStatus = STATUS.SUCCESS;
            const res = action.payload.response;
            state.mentorshipSessionStartData = res;

        },
        [startMentorshipSessionAction.rejected]: (state) => {
            state.startMentorshipSessionStatus = STATUS.FAILED;
        },


        [endMentorshipSessionAction.pending]: (state) => {
            state.endMentorshipSessionStatus = STATUS.FETCHING;
        },
        [endMentorshipSessionAction.fulfilled]: (state, action) => {
            state.endMentorshipSessionStatus = STATUS.SUCCESS;
            state.sessionCompleted = action.payload?.extraData?.isCompleted;
        },

        [endMentorshipSessionAction.rejected]: (state) => {
            state.endMentorshipSessionStatus = STATUS.FAILED;
        },


        [addMentorFeedbackAction.pending]: (state) => {
            state.mentorFeedbackStatus = STATUS.FETCHING;
        },
        [addMentorFeedbackAction.fulfilled]: (state, action) => {
            state.mentorFeedbackStatus = STATUS.SUCCESS;
        },
        [addMentorFeedbackAction.rejected]: (state) => {
            state.mentorFeedbackStatus = STATUS.FAILED;
        }
    },
});

export const { resetEndMentorshipSessionStatus, resetStartMentorshipSessionStatus, resetMentorFeedbackStatus, setMentorshipCurrentSessionData } = mentorshipSlice.actions;
export const mentorshipReducer = mentorshipSlice.reducer;
