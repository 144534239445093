import React, { useReducer, useState, useEffect, useCallback } from "react";
import { STATUS } from "../../App/Constants";
import {
    Box,
    Image,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Button,
    Spacer,
    Text,
    InputGroup,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    IconButton,
    useColorMode,
    useDisclosure,
    Badge,
    useToast,
    HStack,
    Select,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Divider,
    InputLeftElement,
} from "@chakra-ui/react";
import { useAppContext, useIsAuthenticated } from "../../App/Context";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Search2Icon, PhoneIcon, HamburgerIcon, SearchIcon } from "@chakra-ui/icons";
import { IoPersonCircleOutline } from "react-icons/io5";
import { useWindowSize } from "../../utils/useWindowSize";
import { LoginRoute } from "../../routes/Login";
import $ from "jquery";
import CartContext from "../../Cart/useCart";
import { AiOutlineLogin, AiOutlineLogout, AiOutlineBell } from "react-icons/ai";
import { CUSTOM_ROUTES, ROUTES } from "../../Constants/Routes";
import { BasicDetailsComponent } from "../../routes/BasicDetailsComponent";
import { Dropdown, ProfileDropdown } from "../ProfileDropdown";
import { useLoginModal } from "../../App/useLoginModal";
import { CONTACT_IT_HELP, CONTACT_OTHER } from "../../Constants/Config";
import { BiMenu } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { SideBarContent } from "../Sidebar";
import { mobileView, webView } from "../../utils/Helper";

export const CommonHeader = ({ pageContext, config }) => {
    const context = React.useContext(CartContext);
    const isAuthenticated = useIsAuthenticated();
    const [width, height] = useWindowSize();
    const { logout } = useAppContext();
    const history = useHistory();
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

    const { user } = useSelector((s) => ({
        user: s.user,
    }));

    const { colorMode, toggleColorMode } = useColorMode();

    const { modalType, setModalType, closeLoginModal, openLoginModal, toggleLoginModal } = useLoginModal();


    const _signInOut = () => {
        if (isAuthenticated) {
            logout();
        } else {
            setModalType(SIGNIN_MODAL_TYPE.SIGNIN);
            toggleLoginModal();
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            if (modalType !== SIGNIN_MODAL_TYPE.COUPON_LOGIN) {
                closeLoginModal();
            }
        }
    }, [modalType, isAuthenticated, closeLoginModal]);

    return (
        <Box style={{ position: "sticky", top: "0",zIndex:"2" }}>
            <Box {...mobileView}>
                <Box>
                    <Box backgroundImage="url(/images/Header_1920.jpg)">
                        <HStack justifyContent="space-between">
                            <Box w="33%">
                                <MobileDrawer />
                            </Box>
                            <HStack py={3} w="33%" justifyContent="center">
                                <Image
                                    w="100px"
                                    src={"/logo-white.png"}
                                    alt="Rankplus"
                                    cursor="pointer"
                                  
                                />
                            </HStack>
                            <HStack justifyContent="flex-end" w="33%">
                                {isAuthenticated ? (
                                    <HStack>
                                        <Box>
                                            <Box pos="relative" cursor="pointer" onClick={() => history.push("/notifications")}>
                                                {context?.cart?.packages?.length ? (
                                                    <Badge position="absolute" top={-2} right={-1} zIndex={9} background="#E74C3C" variant="solid" fontSize="10px">
                                                        {context.cart.packages.length}
                                                    </Badge>
                                                ) : null}
                                                <AiOutlineBell fontSize="20px" color="#95A2E5" />
                                            </Box>
                                        </Box>

                                        <IconButton onClick={_signInOut} variant="ghost" fontSize="20px" color="#95A2E5" icon={<AiOutlineLogout />} />
                                    </HStack>
                                ) : (
                                    <IconButton fontSize="20px" color="#95A2E5" onClick={_signInOut} variant="ghost" icon={<AiOutlineLogin />} />
                                )}
                            </HStack>
                        </HStack>
                    </Box>
                </Box>
            </Box>
            <Box {...webView} color="white">
                <Box
                    pos="relative"
                    backgroundColor="#4559D2"
                    backgroundImage="url(/images/Header_1920.jpg)"
                    backgroundRepeat="no-repeat"
                    // backgroundSize='100%'
                    h={"3.51vw"}
                >
                    <HStack pos="absolute" px={3} right={0} left={0} top={0} bottom={0} alignItems="center" wrap>
                        <HStack w={"25%"} style={{ verticalAlign: "middle" }} align="center" py={2} spacing={4}>
                            <Image
                                w="6.80vw"
                                src={"/logo-white.png"}
                                alt="Rankplus logo"
                                cursor="pointer"
                                // onClick={() => history.push("/")}
                            />
                            {/* <Text>Rankplus Live</Text> */}
                        </HStack>
                        <Spacer />
                        <Box />
                        <Spacer />
                        <Box>
                            <SideBarContent top />
                        </Box>
                        <HStack align="center">
                            <HStack wrap="wrap" spacing={6}>
                               
                                {user.user ? (
                                    <HStack spacing={3}>
                                        <Box>
                                            <Box pos="relative" onClick={() => history.push("/notifications")} cursor="pointer">
                                                <AiOutlineBell fontSize="1.52vw" color="#fff" />
                                            </Box>
                                        </Box>
                                    </HStack>
                                ) : null}
                            </HStack>
                        </HStack>
                        <Box display="flex" alignItems="center">
                            {isAuthenticated ? (
                                <span
                                    width="300px"
                                    style={{
                                        display: "inline-block",
                                        color: "#fff",
                                        fontSize: "",
                                        padding: "",
                                    }}
                                >
                                    <ProfileDropdown />
                                </span>
                            ) : (
                                <span
                                    style={{
                                        display: "inline-block",
                                        color: "#717678",
                                        fontSize: "",
                                        padding: "",
                                        cursor: "pointer",
                                    }}
                                    onClick={_signInOut}
                                >
                                    <Button
                                        variant="ghost"
                                        colorScheme="white"
                                        fontWeight="normal"
                                        leftIcon={<IoPersonCircleOutline style={{ fontSize: "22px" }} />}
                                        _focus={{ boxShadow: "none" }}
                                        fontSize="sm"
                                        style={{ color: "#fff" }}
                                    >
                                        {isAuthenticated ? "Sign Out" : "Sign In"}
                                    </Button>
                                </span>
                            )}
                        </Box>
                    </HStack>
                </Box>
               
                <SignInFlow />
                
                {isOpen ? <Dropdown isOpen={isOpen} user={user} close={onClose} /> : null}
            </Box>
        </Box>
    );
};

function MobileDrawer() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const history = useHistory();


    const isAuthenticated = useIsAuthenticated();

    const { logout } = useAppContext();
    const { setModalType, toggleLoginModal } = useLoginModal();

    const _signInOut = () => {
        if (isAuthenticated) {
            logout();
        } else {
            setModalType(SIGNIN_MODAL_TYPE.SIGNIN);
            toggleLoginModal();
        }
        onClose();
    };

    return (
        <>
            <IconButton colorScheme="url(/images/Header_1920.jpg)" icon={<HamburgerIcon />} onClick={onOpen} />
            <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader fontWeight="bold" color="gray.800" bgColor="gray.50">
                        Competition Community
                    </DrawerHeader>
                    <Divider />
                    <DrawerBody>
                        {!isAuthenticated ? (
                            <Box py={2} onClick={_signInOut}>
                                {isAuthenticated ? "Sign Out" : "Sign In"}
                            </Box>
                        ) : null}
                        {isAuthenticated ? <SideBarContent /> : null}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
}

const MenuComponent = ({ isAuthenticated, signInOut, hamburger }) => {
    const history = useHistory();
    const toast = useToast();
  
    const handleSignIn = () => {
        toast({
            title: "Sign In to Continue.",
            position: "top",
            variant: "subtle",
            status: "info",
            duration: 2500,
            isClosable: true,
        });

        signInOut();
    };

    const location = useLocation();
    const [openMenu, changeOpenMenu] = useState();
    const _openMenu = () => {
      
        changeOpenMenu(!openMenu);
       
    };

 

    return (
        <HStack spacing={7}>
                      {hamburger ? <hr style={{ paddingTop: "5px", paddingBottom: "3px" }} /> : null}
        </HStack>
    );
};

export const SIGNIN_MODAL_TYPE = {
    SIGNIN: "signin",
    COUPON_LOGIN: "coupon",
};

const SignInFlow = () => {
    const { modalType, isLoginModalOpen, toggleLoginModal } = useLoginModal();

    return (
        <Modal size="xl" isOpen={isLoginModalOpen} onClose={toggleLoginModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Sign In</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <LoginRoute />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const CompleteProfilePopUp = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isConfirmed, setIsConfirmed] = useState(null);

    const { user } = useSelector((s) => ({
        user: s.user.user,
    }));

    useEffect(() => {
        if (user && !user.confirmed) {
            setIsConfirmed(false);
            onOpen();
        } else {
            setIsConfirmed(true);
            onClose();
        }
    }, [onClose, onOpen, user]);

    const toast = useToast();

    const handleModalClose = () => {
        if (isConfirmed) {
            onClose();
        } else {
            toast.closeAll();
            toast({
                title: "Please Complete your Profile first.",
                position: "top",
                variant: "subtle",
                status: "warning",
                duration: 2500,
                isClosable: false,
            });
        }
    };

    return (
        <Modal size="xl" isOpen={isOpen} onClose={handleModalClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Complete Your Profile</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <BasicDetailsComponent />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const SearchBarHeader = ({ width }) => {
    const history = useHistory();
    const [searchData, changeSearchData] = useState("");
    const [searchType, changeSearchType] = useState("all");

    const search = (e) => {
        e.preventDefault();
        history.push(ROUTES.PACKAGES + `?search=${searchData}&searchType=${searchType}`);
    };

   
    return (
        <Box w={width} p={[3, 3, 3, 0]} bg={["gray.50", "gray.50", "transparent"]}>
            <form onSubmit={search}>
               
                <InputGroup>
                    <InputLeftElement {...mobileView} pointerEvents="none" my={2} children={<SearchIcon color="gray.300" />} />
                    <Select
                        {...webView}
                        size="sm"
                        defaultValue={"exam"}
                        color="black"
                        w="30%"
                        borderRightRadius={0}
                        borderLeftRadius={3}
                        background="white"
                        onChange={(e) => changeSearchType(e.target.value)}
                    >
                        <option value="all" style={{ padding: 4 }}>
                            All
                        </option>
                        <option value="COURSE" style={{ padding: 4 }}>
                            Courses
                        </option>
                        <option value="TEST" style={{ padding: 4 }}>
                            Tests
                        </option>
                        <option value="BOOK" style={{ padding: 4 }}>
                            Books
                        </option>
                        <option value="MAGAZINE" style={{ padding: 4 }}>
                            Magazines
                        </option>

                                  </Select>

                    <Input
                        size={["lg", "lg", "lg", "sm"]}
                        background="white"
                        color="black"
                        py={[2, 2, 2, 0]}
                        borderRadius={["full", "full", "full", 0]}
                        placeholder="What do you want to learn"
                        variant={["outline", "outine", "filled"]}
                        onChange={(e) => changeSearchData(e.target.value)}
                                  />
                    <IconButton
                        size="sm"
                        width="10%"
                        {...webView}
                        type="submit"
                                      icon={<Search2Icon />}
               
                        colorScheme="yellow"
                        borderRightRadius={3}
                        borderLeftRadius={0}
                        color="white"
                    />
                </InputGroup>
            </form>
            <Box {...mobileView} p={3} mt={2} textAlign="center">
                           </Box>
        </Box>
    );
};
